<script>
  import { Dialog, Button, Icon, Field, Input, Switch, Toast } from 'svelma-fixed'
  import { createEventDispatcher, tick } from 'svelte'
  import { quoteCoin } from '../stores/quoteCoin'
  import { formatCurrency, formatPercentage } from '../lib/utils'
  import { calculatorState, currentCoreData } from '../stores/calculatorState'
  import markets, { getDispSymbol } from '../stores/markets'
  import { calculateFullState } from '../lib/liquidationCalculator'
  import { connectedWallet, currentProvider } from '../stores/walletManager'
  import { qclone } from 'qclone'
  import { slide } from 'svelte/transition'
  import { createLoadingStore } from '../stores/loading'
  import RadioGroup from './RadioGroup.svelte'
  import { ga } from '@beyonk/svelte-google-analytics'
  import UpdateAssetDialog from './UpdateAssetDialog.svelte'
  import dialogs from '../stores/dialogs'
  import ActionTypeDropdownDialog from './ActionTypeDropdownDialog.svelte'
  import html from 'html-template-tag'
  import { validate as validateBtcAddress } from 'bitcoin-address-validation'
  import { CancelledError } from '../lib/errors'

  export let actionId = 'custom'
  export let title = 'Wallet Action'
  export let data = {}
  export let customIsValid = data => true
  export let onSubmit = async data => true
  export let getSubmitLabel = data => 'Submit'
  export let getPreviewState = (baseState, data) => baseState
  export let hidePreview = false
  export let quoteCoinSymbol = $quoteCoin.symbol
  export let connectedWalletAddress = $connectedWallet.address
  export let hasAmount = false
  export let hasEnableCheckbox = false
  export let isEnableStateForced = false
  export let hasInterestModeSelector = false
  export let assetType = null
  export let source = null
  export let secondarySource = null
  export let target = null
  export let operationLabel
  export let isNew = false
  export let allButtonRatio = 0.8
  export let actions = {} // For converting self to a new action

  const submitLoading = createLoadingStore()
  const dispatch = createEventDispatcher()
  $: if (quoteCoinSymbol !== $quoteCoin.symbol) dispatch('close')
  $: if (connectedWalletAddress !== $connectedWallet?.address) dispatch('close')

  let btcAddressElement
  if (data.symbol === 'WBTC') {
    data.useUnwrapped = false // For THORChain, (un)wrapping should not be the default
    data.btcAddress = ''
  }

  // Note: Prices intentionally don't live-update inside of this dialog window because it would over-complicate things. So this only reacts on symbol change.
  let assetData = {}
  $: assetData = getAssetData(data.symbol, data.useUnwrapped)

  const { assetPrices, quoteCoinPrices } = $calculatorState
  const fullState = calculateFullState({ assetPrices, quoteCoinPrices }, $quoteCoin, $markets, $currentCoreData)

  $: augmentedAssetForUpdateDialog = {
    symbol: data.symbol,
    coin: $markets.coins[data.symbol],
    serviceData: $markets.coins[data.symbol]?.services[fullState.service],
    price: assetData.price
  }

  $: previewRows = getPreviewRows(data)
  $: liquidationDanger = previewRows.some(row => row.isSafetyMargin && row.newValue <= 0.05 && row.newValue < fullState.calcValues.marketDropRatio)
  $: impossible = previewRows.some(row => row.isSafetyMargin && row.newValue <= 0.0001 && row.newValue < fullState.calcValues.marketDropRatio)
  $: notAllowed = (actionId === 'deposit' || actionId === 'borrow') && (!augmentedAssetForUpdateDialog.serviceData?.[`${assetType}Active`] || augmentedAssetForUpdateDialog.serviceData?.removeOnly)

  function getPreviewRows (data) {
    if (hidePreview) return []

    const oldState = fullState
    const newBaseState = getPreviewState(qclone(oldState), data)
    const newState = calculateFullState({ assetPrices, quoteCoinPrices }, $quoteCoin, $markets, newBaseState)

    const rows = []

    rows.push({
      label: 'Safety Margin',
      isSafetyMargin: true,
      oldValue: Math.max(0, oldState.calcValues.marketDropRatio),
      oldColor: oldState.calcValues.circleInnerColor,
      newValue: Math.max(0, newState.calcValues.marketDropRatio),
      newColor: newState.calcValues.circleInnerColor
    })

    if (assetType === 'collateral') {
      rows.push({
        label: `${assetData?.dispSymbol ?? 'Asset'} Collateral`,
        oldValue: !data.symbol ? null : (oldState.assets.collateral.find(asset => asset.symbol === data.symbol)?.quoteValue ?? 0),
        oldEnabled: !data.symbol ? true : (oldState.assets.collateral.find(asset => asset.symbol === data.symbol)?.enabled ?? true),
        newValue: !data.symbol ? null : (newState.assets.collateral.find(asset => asset.symbol === data.symbol)?.quoteValue ?? 0),
        newEnabled: !data.symbol ? true : (newState.assets.collateral.find(asset => asset.symbol === data.symbol)?.enabled ?? true)
      })
    } else if (assetType === 'borrow') {
      rows.push({
        label: `${assetData?.dispSymbol ?? 'Asset'} Borrow`,
        oldValue: !data.symbol ? null : (oldState.assets.borrow.find(asset => asset.symbol === data.symbol)?.quoteValue ?? 0),
        oldEnabled: !data.symbol ? true : (oldState.assets.borrow.find(asset => asset.symbol === data.symbol)?.enabled ?? true),
        newValue: !data.symbol ? null : (newState.assets.borrow.find(asset => asset.symbol === data.symbol)?.quoteValue ?? 0),
        newEnabled: !data.symbol ? true : (newState.assets.borrow.find(asset => asset.symbol === data.symbol)?.enabled ?? true)
      })
    }

    rows.push({
      label: 'Overall Collateral',
      oldValue: oldState.calcValues.collateralValue,
      oldEnabled: true,
      newValue: newState.calcValues.collateralValue,
      newEnabled: true
    })

    rows.push({
      label: 'Overall Borrow',
      oldValue: oldState.calcValues.borrowValue,
      oldEnabled: true,
      newValue: newState.calcValues.borrowValue,
      newEnabled: true
    })

    rows.push({
      label: 'Liquidation Threshold',
      oldValue: oldState.calcValues.thresholdCollateralValue,
      oldEnabled: true,
      newValue: newState.calcValues.thresholdCollateralValue,
      newEnabled: true
    })

    return rows
  }

  function getAssetData (symbol, useUnwrapped = false) {
    if (!symbol) return {}

    let unwrappedSymbol
    const dispSymbol = getDispSymbol(symbol, fullState.service)
    // TODO: Use a better way, this is quite a hack! Also in WalletActions.svelte where W is removed on useUnwrapped...
    if (symbol.startsWith('W') && dispSymbol.startsWith('(W)')) {
      unwrappedSymbol = symbol.slice(1)
    } else if (symbol === 'WBTC') { // THORChain support
      unwrappedSymbol = 'BTC'
    }

    if (!unwrappedSymbol) useUnwrapped = false

    const price = (fullState.serviceAssetPrices[fullState.service]?.[symbol] ?? $markets.coins[symbol].services[fullState.service]?.price ?? $markets.coins[symbol].price)
    const data = {
      dispSymbol,
      unwrappedSymbol,
      balanceSymbol: useUnwrapped ? unwrappedSymbol : symbol,

      price,
      quotePrice: price * fullState.quoteCoinEthPrice,
      balance: ($connectedWallet.liveData.balances.find(asset => asset.symbol === (useUnwrapped ? unwrappedSymbol : symbol))?.units) ?? 0,
      supply: fullState.assets.collateral.find(asset => asset.symbol === symbol)?.units ?? 0,
      borrow: fullState.assets.borrow.find(asset => asset.symbol === symbol)?.units ?? 0
    }

    if (unwrappedSymbol === 'BTC') {
      data.balance = Infinity
    }

    data.borrowLimit = fullState.calcValues.borrowLimit / data.quotePrice
    data.borrowLimitLeft = (fullState.calcValues.borrowLimit - fullState.calcValues.borrowValue) / data.quotePrice
    const borrowedInEquivalentUnits = data.borrowLimit - data.borrowLimitLeft
    data.allValue = source === 'borrowLimitLeft' ? data.borrowLimit * allButtonRatio - borrowedInEquivalentUnits : data[source]
    data.max = data[source] ?? Infinity

    data.sourceDispSymbol = source === 'balance' ? data.balanceSymbol : data.dispSymbol
    data.secondarySourceDispSymbol = secondarySource === 'balance' ? data.balanceSymbol : data.dispSymbol
    data.targetDispSymbol = target === 'balance' ? data.balanceSymbol : data.dispSymbol

    return data
  }

  let formEl
  let useUnits = false
  let updateAssetDialog

  // For some reason here no intro gets shown so on:introend wouldn't work...
  function onModalMount () {
    ga.addEvent('wallet_action_dialog', { address: 'addr:' + connectedWalletAddress, service: fullState.service, action: actionId })
  }

  function setAll () {
    data.amount = assetData.allValue
    data.all = true

    updateAssetDialog.setUnits(data.amount)
  }

  function isValid (data) {
    if (hasAmount && !(data.amount > 0 && data.amount <= assetData.max)) return false
    if (hasAmount && secondarySource && data.amount > (assetData[secondarySource] || 0)) return false

    return customIsValid(data)
  }

  function getSubtextLabel (type) {
    return {
      balance: 'Wallet Balance',
      supply: 'Collateral Supply',
      borrow: 'Borrowed',
      borrowLimitLeft: 'Remaining Borrow Limit'
    }[type] ?? type
  }

  async function submit () {
    await submitLoading(async () => {
      try {
        if (btcAddressElement) {
          data.btcAddress = data.btcAddress.trim()
          if (!validateBtcAddress(data.btcAddress, 'mainnet')) {
            // FIXME: This should be its own variable. But for some reason reactivity didn't work in this specific case, not even with a store! Why? I have no clue!
            data.btcAddressError = data.btcAddress ? 'Invalid BTC address' : 'BTC address is required'
            btcAddressElement.querySelector('input').scrollIntoView({ behavior: 'smooth', block: 'center' })
            setTimeout(() => btcAddressElement.querySelector('input').focus(), 250)
            Toast.create({ message: data.btcAddressError, type: 'is-danger' })
            return false
          }

          if (await $currentProvider.getCode($connectedWallet.address) !== '0x') {
            throw new Error('Your Ethereum wallet is a contract wallet. Contract wallets are not supported by THORChain for security reasons!')
          }
        }

        if (await onSubmit(data) !== false) {
          dispatch('submit', data)
          dispatch('close')

          ga.addEvent('wallet_action_submit', { address: 'addr:' + connectedWalletAddress, service: fullState.service, action: actionId, data })
        }
      } catch (e) {
        console.error('Action error', e)

        if (!(e instanceof CancelledError)) {
          Dialog.alert({
            message: html`
              <p class="mb-3">
                Something went wrong:
              </p>
              <p>
                ${e.serverErrorMessage ?? e.message}
              </p>
            `,
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
        }
      }
    })
  }

  async function actionTypeDropdownClick () {
    const isEnableStateForced = augmentedAssetForUpdateDialog.serviceData?.collateralForcedEnableState != null
    const newActionId = await dialogs.open(ActionTypeDropdownDialog, { actionId, assetType, assetEnabled: $currentCoreData.assets.collateral.find(asset => asset.symbol === data.symbol)?.enabled, isEnableStateForced })
    if (newActionId && newActionId !== actionId) {
      actions[newActionId]?.(data.symbol, isNew)
    }
  }

  // Because Svelma uses context, we have to use {#key} to update the input validity state, but this destroys focus and cursor position
  // This function attempts to fix that
  function restoreBtcAddressSelection () {
    const { selectionStart, selectionEnd } = btcAddressElement.querySelector('input')
    tick().then(() => {
      const newInputEl = btcAddressElement.querySelector('input')
      newInputEl.focus()
      newInputEl.setSelectionRange(selectionStart, selectionEnd)
    })
  }
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .level-below-field {
    align-items: flex-start;
    margin-top: 4px;

    .level-item {
      display: block;
    }
  }

  .label {
    font-size: 1rem;
    line-height: 1;
    margin-top: 1.5rem;
  }

  :global(.field) + .subtext, :global(.control) + .subtext {
    margin-top: 0.5em;
  }

  .subtext {
    display: block;
    font-size: 0.8rem;
  }

  .preview-grid {
    display: grid;
    grid-template-columns: 1fr 72px 10px 72px;
    grid-template-areas:
      "label oldValue arrow newValue";
    align-items: center;
    font-size: 75%;
    color: $grey;

    .grid-element {
      grid-area: var(--area);
    }

    .preview-label {
      justify-self: start;
    }

    .preview-value, .preview-arrow {
      justify-self: center;
    }

    .tag {
      padding: 0 0.5em;
      height: 1.75em;
    }

    &:has(.isSafetyMargin) {
      margin-bottom: 0.5rem;
    }
  }
</style>

<UpdateAssetDialog
  bind:this={updateAssetDialog}
  on:close
  type={assetType}
  service={fullState.service}
  empty
  title={null}
  hasQuantity={hasAmount}
  bind:units={data.amount}
  augmentedAsset={augmentedAssetForUpdateDialog}
  dispSymbolOverride={assetData.balanceSymbol}
  on:mount={onModalMount}
  disabled={$submitLoading || notAllowed}
  onSubmit={submit}
  bind:useUnits
  bind:formEl
  on:change={() => (data.all = false)}
>
  <svelte:fragment slot="title">
    {#if isNew}
      {title}
    {:else}
      <a href={undefined} class="black-link large-icon" on:click={actionTypeDropdownClick}><Icon icon="caret-down" /> {title}</a>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="beforeFields">
    {#if notAllowed}
      <div class="notification is-danger" transition:slide|local>
        This operation is not possible because the asset is frozen or no longer available in the DeFi protocol.
      </div>
    {/if}

    <slot name="custom" />

    {#if hasAmount}
      {#if assetData.unwrappedSymbol}
        <div class="notification" transition:slide|local style:max-width="660px">
          {#if assetData.unwrappedSymbol !== 'BTC'}
            This De-Fi protocol internally uses wrapped tokens ({data.symbol}), but you can decide to {actionId} the unwrapped tokens ({assetData.unwrappedSymbol}) instead. The tokens will be automatically wrapped/unwrapped for you.
          {:else}
            It is possible to {actionId} the wrapped tokens ({data.symbol}) directly as {assetData.unwrappedSymbol} using <a href="https://thorchain.org/" target="_blank" rel="noopener noreferrer">THORChain</a>.
          {/if}
          <div class="mt-1">
            <RadioGroup bind:value={data.useUnwrapped} options={[[false, `Use ${data.symbol}`], [true, `Use ${assetData.unwrappedSymbol} (extra gas cost)`]]} disabled={$submitLoading} on:change={() => (data.all = false)} />
          </div>
          {#if assetData.unwrappedSymbol === 'BTC' && target === 'balance' && data.useUnwrapped}
            <span bind:this={btcAddressElement}>
              {#key data.btcAddressError}
                <Field type={data.btcAddressError ? 'is-danger' : undefined} message={data.btcAddressError}>
                  <Input placeholder="BTC Address" bind:value={data.btcAddress} required disabled={$submitLoading} on:input={() => { delete data.btcAddressError; restoreBtcAddressSelection() }} />
                </Field>
              {/key}
            </span>
          {/if}
        </div>
      {/if}
    {/if}

    {#if actionId === 'swapToBtc'}
      <span bind:this={btcAddressElement}>
        {#key data.btcAddressError}
          <Field type={data.btcAddressError ? 'is-danger' : undefined} message={data.btcAddressError} class="mb-5">
            <Input placeholder="BTC Address" bind:value={data.btcAddress} required disabled={$submitLoading} on:input={() => { delete data.btcAddressError; restoreBtcAddressSelection() }} />
          </Field>
        {/key}
      </span>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="quantityFieldAfterContent">
    <div class="level is-mobile level-below-field">
      <div class="level-left">
        <div class="level-item">
          {#if useUnits}
            {#if source && assetData[source] !== Infinity}
              <span class="subtext" class:has-text-danger={data.amount > (assetData[source] ?? 0)}>{getSubtextLabel(source)}: {data.symbol ? formatCurrency(assetData[source] ?? 0, undefined, -6, '-', true) : '-'} {assetData?.sourceDispSymbol ?? ''} {source === 'borrowLimitLeft' && data.symbol ? `(${formatPercentage(assetData.borrowLimitLeft / assetData.borrowLimit, 0, 0, 'n/a')})` : ''}</span>
            {/if}

            {#if secondarySource && assetData[secondarySource] !== Infinity}
              <span class="subtext" class:has-text-danger={data.amount > (assetData[secondarySource] ?? 0)}>{getSubtextLabel(secondarySource)}: {data.symbol ? formatCurrency(assetData[secondarySource] ?? 0, undefined, -6, '-', true) : '-'} {assetData?.secondarySourceDispSymbol ?? ''} {source === 'borrowLimitLeft' && data.symbol ? `(${formatPercentage(assetData.borrowLimitLeft / assetData.borrowLimit, 0, 0, 'n/a')})` : ''}</span>
            {/if}

            {#if target && assetData[target] !== Infinity}
              <span class="subtext">{getSubtextLabel(target)}: {data.symbol ? formatCurrency(assetData[target] ?? 0, undefined, -6, '-', true) : '-'} {assetData?.targetDispSymbol ?? ''}</span>
            {/if}
          {:else}
            {#if source && assetData[source] !== Infinity}
              <span class="subtext" class:has-text-danger={data.amount > (assetData[source] ?? 0)}>{getSubtextLabel(source)}: {data.symbol ? formatCurrency((assetData[source] ?? 0) * assetData.quotePrice, quoteCoinSymbol, undefined, '-', true) : '-'}</span>
            {/if}

            {#if secondarySource && assetData[secondarySource] !== Infinity}
              <span class="subtext" class:has-text-danger={data.amount > (assetData[secondarySource] ?? 0)}>{getSubtextLabel(secondarySource)}: {data.symbol ? formatCurrency((assetData[secondarySource] ?? 0) * assetData.quotePrice, quoteCoinSymbol, undefined, '-', true) : '-'}</span>
            {/if}

            {#if target && assetData[target] !== Infinity}
              <span class="subtext">{getSubtextLabel(target)}: {data.symbol ? formatCurrency((assetData[target] ?? 0) * assetData.quotePrice, quoteCoinSymbol, undefined, '-', true) : '-'}</span>
            {/if}
          {/if}
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          {#if !((data.symbol === 'ETH' || ((data.symbol === 'WETH' || data.symbol === 'WBTC') && data.useUnwrapped)) && source === 'balance') && data.symbol !== 'BTC'}
            <span class="mini-button">
              <Button size="is-small" {...data.all ? { disabled: true, type: 'is-dark' } : { disabled: (assetData.allValue ?? 0) <= 0, type: 'is-primary' } } on:click={setAll}>{source === 'borrowLimitLeft' ? formatPercentage(allButtonRatio, 0, 2) : 'Max'}</Button>
            </span>
          {/if}
        </div>
      </div>
    </div>
  </svelte:fragment>

  <svelte:fragment slot="afterFields">
    {#if operationLabel}
      <h2 class="label">{operationLabel}</h2>
    {/if}

    {#if hasEnableCheckbox}
      <div class="mt-3">
        <Field>
          <Switch size="is-small" disabled={$submitLoading || isEnableStateForced} bind:checked={data.enable}><small>Use as Collateral</small></Switch>
        </Field>
      </div>
    {/if}

    {#if hasInterestModeSelector}
      <div class="mt-3">
        <RadioGroup bind:value={data.interestRateMode} options={[['Variable', 'Variable interest mode'], ['Stable', 'Stable interest mode']]} disabled={$submitLoading} />
      </div>
    {/if}

    {#if previewRows?.length}
      <div class="label">Preview</div>
    {/if}

    {#each previewRows as row}
      <div class="preview-grid">
        <div class="grid-element preview-label" style:--area="label">{row.label}</div>
        {#each [[true, row.oldValue, row.oldEnabled, row.oldColor], [false, row.newValue, row.newEnabled, row.newColor]] as [isOld, value, enabled, color]}
          <div class="grid-element preview-value preview-value-{isOld ? 'old' : 'new'}" class:isSafetyMargin={row.isSafetyMargin} style:--area={isOld ? 'oldValue' : 'newValue'}>
            {#if row.isSafetyMargin}
              <span class="tag is-rounded" style:background-color={color} style:color="white">{formatPercentage(value, 2, 2, '-')}</span>
            {:else}
              {formatCurrency(value, quoteCoinSymbol, undefined, '-', true)}
            {/if}
          </div>

          {#if isOld}
            <div class="grid-element preview-arrow" style:--area="arrow">&rarr;</div>
          {/if}
        {/each}
      </div>
    {/each}

    {#if impossible}
      <div class="notification is-danger" transition:slide|local>
        This operation is not possible because you would have less collateral than borrow.
      </div>
    {:else if liquidationDanger}
      <div class="notification is-danger" transition:slide|local>
        After this operation, you will be at a high risk of getting liquidated!
      </div>
    {:else if data.symbol === 'WBTC' && data.useUnwrapped && source === 'balance'}
      <div class="notification is-warning" transition:slide|local>
        <strong>Warning:</strong> This is a rough estimation, not taking fees into account. Since you will be depositing BTC, there is a swap via THORChain involved before the actual deposit to the DeFi protocol. The actual amount will be less due to swap fees and network fees. You will see another preview after the swap.
      </div>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="button">
    <!-- FIXME: Here, "loading" is not updating when $submitLoading changes. It's not clear why, probably a similar bug as above. -->
    <Button type={liquidationDanger ? 'is-danger' : 'is-primary'} nativeType="submit" disabled={!isValid(data) || impossible || notAllowed} loading={$submitLoading}>{getSubmitLabel(data)}</Button>
  </svelte:fragment>
</UpdateAssetDialog>

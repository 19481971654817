<svelte:options accessors />

<script>
  import { onMount, onDestroy, tick } from 'svelte'
  import { Button, Input, Toast, Dialog } from 'svelma-fixed'
  import '../lib/api' // For correct initialization order to resolve circular dependency between auth and walletManager
  import { mode, activeWallet, switchMode, connectBlocknative, walletConnecting, searchWallet, registeredWallets, connectedWallet, updateLiveData } from '../stores/walletManager'
  import { combineLoadingStores, createLoadingStore } from '../stores/loading'
  import html from 'html-template-tag'
  import { ga } from '@beyonk/svelte-google-analytics'
  import dialogs from '../stores/dialogs'
  import WalletManager from './WalletManager.svelte'
  import { RouterLink, router } from '../router'
  import ConnectedWalletInfo from './ConnectedWalletInfo.svelte'
  import devOptions from '../stores/devOptions'

  let navbarMenu
  let hamburgerIsActive = false

  let searchAddressValue = ''

  const manageOrConnectActionLoading = createLoadingStore()
  const searchAddressLoading = createLoadingStore()
  const somethingLoading = combineLoadingStores(manageOrConnectActionLoading, searchAddressLoading, walletConnecting)

  $: recentWallets = Object.values($registeredWallets).sort((a, b) => (b.lastSelected || 0) - (a.lastSelected || 0)).slice(0, 3)

  let currentRoute = $router.currentRoute
  $router.onNavigationChanged((from, to) => {
    currentRoute = to
  })

  onMount(() => {
    document.documentElement.classList.add('has-navbar-fixed-top')

    // This has to be done at the bubbling phase to avoid having the element removed already after it was clicked (so e.target.closest would not work)!
    navbarMenu.addEventListener('click', e => {
      if ((e.target.closest('a') || e.target.closest('button')) && !e.target.closest('.keep-menu-open')) {
        tick().then(() => (hamburgerIsActive = false))
      }

      // Make sure the dropdowns won't just stay open after clicking an element on them
      // https://github.com/jgthms/bulma/issues/2514
      const dropdown = e.target.closest('.navbar-dropdown')
      if (dropdown) {
        const dropdownOuter = e.target.closest('.has-dropdown')
        dropdownOuter.classList.remove('is-hoverable')
        dropdown.style.display = 'none'
        setTimeout(() => {
          dropdownOuter.blur()
          dropdownOuter.classList.add('is-hoverable')
          dropdown.style.display = ''
        }, 150)
      }
    }, true)
  })
  onDestroy(() => {
    document.documentElement.classList.remove('has-navbar-fixed-top')
  })

  function toggleMenu () {
    hamburgerIsActive = !hamburgerIsActive
  }

  export async function calculate () {
    if ($somethingLoading) return
    await manageOrConnectActionLoading(async () => {
      if ($mode !== 'readOnly' && !$walletConnecting && $activeWallet?.address) {
        switchMode('readOnly')
      }

      await tick()
      if (currentRoute?.name !== 'calculator') {
        $router.push({ name: 'calculator' })
      }
    })
  }

  export async function manage () {
    if ($somethingLoading) return
    await manageOrConnectActionLoading(async () => {
      if (!$connectedWallet && !$walletConnecting) {
        await connectBlocknative(true)
      } else if ($connectedWallet && $mode === 'readOnly') {
        switchMode('blocknative')
        await updateLiveData()
      }

      await tick()
      if (currentRoute?.name !== 'manage') {
        $router.push({ name: 'manage' })
      }
    })
  }

  export async function connect () {
    if ($somethingLoading) return
    await manageOrConnectActionLoading(async () => {
      await connectBlocknative()
    })
  }

  async function submitSearchAddress () {
    if ($somethingLoading) return
    await searchAddressLoading(async () => {
      const address = searchAddressValue.trim()
      if (!address) return
      try {
        await searchWallet(address, true)

        searchAddressValue = ''

        await tick()
        if (currentRoute?.name !== 'calculator') {
          $router.push({ name: 'calculator' })
        }
      } catch (e) {
        console.error(e)

        if (e.code === 'invalid_address') {
          Toast.create({ message: 'Invalid address!', type: 'is-danger' })
        } else {
          const message = e.serverErrorMessage ?? e.message
          Dialog.alert({
            message: html`
              <p class="mb-3">
                The wallet address <strong>${address}</strong> could not be loaded!
              </p>
              <p>
                ${message}
              </p>
            `,
            type: 'is-danger',
            icon: 'exclamation-circle'
          })

          ga.addEvent('error', { operation: 'load_wallet', error: message, address: 'addr:' + address })
        }
      }
    })
  }

  async function onSearchFormKeyPress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      await submitSearchAddress()
    }
  }
</script>

<style lang="scss">
  // TODO: This is a workaround. We would normally *want* to have this scrollable on mobile/tablet, because otherwise things could become hidden
  // if there are too many navbar items, especially with subdropdown, if any. But this makes the coin selector break. We should find some other
  // solution.
  .navbar-menu {
    overflow: visible !important;
  }

  .navbar-center-overlay {
    position: absolute;
    width: calc(min(35vw, 600px));
    left: calc(50% - min(35vw, 600px) / 2);
    top: 9px;
    z-index: 1;

    .recent-wallets-dropdown {
      display: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      padding: 0.5em;
      padding-top: 3em;
      background-color: $field-bg;
      border: 1px solid $border;
      border-radius: $radius;
      flex-direction: column;
      gap: 0.25em;

      a {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    :global(.control) {
      z-index: 2;
    }

    :global(.control input) {
      transition: background-color 0.2s ease-in-out, border-bottom-left-radius 0.2s ease-in-out, border-bottom-right-radius 0.2s ease-in-out, border 0.2s ease-in-out;
    }

    :global(.control:focus-within input) {
      background-color: $field-bg;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:focus-within .recent-wallets-dropdown {
      display: flex;
    }
  }
</style>

<nav class="navbar is-fixed-top" aria-label="main navigation">
  <div class="navbar-center-overlay is-hidden-touch">
    <div class="recent-wallets-dropdown">
      {#if recentWallets.length}
        <strong>Latest Wallets</strong>
        {#each recentWallets as wallet (wallet.address)}
          <a tabindex="0" href={undefined} on:click={() => { searchAddressValue = wallet.address; submitSearchAddress(); document.activeElement?.blur() }}>{wallet.name ?? wallet.address}</a>
        {/each}
        <a tabindex="0" href={undefined} class="more" on:click={() => { dialogs.open(WalletManager); document.activeElement?.blur() }}>See more</a>
      {:else}
        <small>Once you search for wallets, your history will appear here.</small>
      {/if}
    </div>
    <Input type="text" placeholder="Import wallet address" icon="search" bind:value={searchAddressValue} expanded disabled={$searchAddressLoading} loading={$searchAddressLoading} on:keypress={onSearchFormKeyPress} />
  </div>

  <div class="navbar-brand">
    <a href="https://www.wehodl.finance/" class="navbar-item py-0">
      <img title="WEHODL" src="/images/logo_square.svg" alt="WEHODL" />
    </a>

    <a role="button" class="navbar-burger burger" class:is-active={hamburgerIsActive} aria-label="menu" aria-expanded="false" data-target="navbarMenu" href={undefined} on:click={toggleMenu}>
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </a>
  </div>

  <div bind:this={navbarMenu} id="navbarMenu" class="navbar-menu" class:is-active={hamburgerIsActive}>
    <div class="navbar-start">
      <a href={undefined} on:click={() => calculate()} class="navbar-item" class:is-active={currentRoute?.name === 'calculator'}>Calculate</a>
      <RouterLink to={{ name: 'compare' }} cls="navbar-item">Compare</RouterLink>
      <a href={undefined} on:click={() => manage()} class="navbar-item" class:is-active={currentRoute?.name === 'manage'}>
        Manage

        {#if $manageOrConnectActionLoading}
          &nbsp;<div class="loader"></div>
        {/if}
      </a>
      {#if $devOptions.featureFlags.thorchainSupport}
        <RouterLink to={{ name: 'thorchain' }} cls="navbar-item">BTC⇆WBTC</RouterLink>
      {/if}
    </div>

    <div class="navbar-center is-hidden-desktop">
      <span class="navbar-item">
        <Input type="text" placeholder="Import wallet address" iconLeft="search" bind:value={searchAddressValue} expanded disabled={$searchAddressLoading} loading={$searchAddressLoading} on:keypress={onSearchFormKeyPress} />
      </span>
      <span class="navbar-item">
        <Button size="is-small" on:click={() => dialogs.open(WalletManager)} disabled={$searchAddressLoading}>Recent Wallets</Button>
      </span>
    </div>

    <div class="navbar-end">
      <span class="navbar-item">
        {#if $connectedWallet}
          <ConnectedWalletInfo />
        {:else}
          <Button type="is-primary" size="is-small" on:click={() => connect()} loading={$walletConnecting || $manageOrConnectActionLoading}>Connect</Button>
        {/if}
      </span>
    </div>
  </div>
</nav>

<script>
  import { Bitcoin, ensureBitcoinLib, generateBitcoinWallet, getBalance, getUtxos, importBitcoinWallet, SATOSHI_MULTIPLIER, sweep } from '../lib/bitcoin'
  import { Button, Dialog, Input } from 'svelma-fixed'
  import { getBtcToWbtcQuote, THORCHAIN_MULTIPLIER } from '../lib/walletActions/thorchain'
  import thorchainBtcDeposit from '../stores/thorchainBtcDeposit'
  import html from 'html-template-tag'

  async function test () {
    await ensureBitcoinLib()
    alert(Bitcoin.opcodes.OP_RETURN)
  }

  function stringifyWithBigInts (key, value) {
    if (typeof value === 'bigint') {
      return value.toString() + 'n'
    }
    return value
  }

  let wallet = null
  let importedPrivateKey = ''
  let balanceInfo = null
  let targetAddress = ''
  let sweepResult = null

  async function handleSweep (dryRun = false) {
    let opReturnData = null
    let txRecipient = targetAddress

    const utxos = await getUtxos(wallet.address)
    if (targetAddress.startsWith('0x')) {
      const { fee } = await sweep(wallet?.address, wallet?.privateKey, wallet?.address, { dryRun: true, opReturnData: Buffer.alloc(80), utxos })
      const { confirmedSat, unconfirmedSat } = await getBalance(wallet.address, utxos)
      const amount = confirmedSat + unconfirmedSat - fee
      const quote = await getBtcToWbtcQuote(Number(amount) / SATOSHI_MULTIPLIER, targetAddress)
      console.log('THORChain quote', quote)
      if (!await Dialog.confirm({ message: html`Balance: ${Number(confirmedSat + unconfirmedSat) / SATOSHI_MULTIPLIER} BTC, sending ${Number(amount) / SATOSHI_MULTIPLIER} BTC, receiving ${quote.expected_amount_out / THORCHAIN_MULTIPLIER} WBTC - continue?` })) return
      txRecipient = quote.inbound_address
      opReturnData = Buffer.from(quote.memo, 'utf-8')
    }

    sweepResult = await sweep(wallet?.address, wallet?.privateKey, txRecipient, { dryRun, opReturnData, utxos })
  }

  async function loadFromLocalStorage () {
    wallet = await importBitcoinWallet($thorchainBtcDeposit.privateKey)
  }

  function saveToLocalStorage () {
    $thorchainBtcDeposit.privateKey = wallet.privateKey
    $thorchainBtcDeposit.address = wallet.address
    $thorchainBtcDeposit.balance = null
  }
</script>

<svelte:head>
	<title>Bitcoin Test - WEHODL</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Bitcoin Test Page
    </h4>

    <div class="card">
      <div class="card-content">
        <Button on:click={test}>Test Bitcoin Library</Button>
        <hr />

        <p>Private Key: {wallet?.privateKey ?? 'N/A'}</p>
        <p>Address: {wallet?.address ?? 'N/A'}</p>
        <br />
        <Button on:click={async () => (wallet = await generateBitcoinWallet())}>Generate Wallet</Button>
        <hr />
        <Input placeholder="Enter private key" bind:value={importedPrivateKey} />
        <br />
        <Button on:click={async () => (wallet = await importBitcoinWallet(importedPrivateKey))} disabled={!importedPrivateKey}>Import Wallet</Button>
        <hr />
        <Button on:click={loadFromLocalStorage} disabled={!$thorchainBtcDeposit.privateKey}>Load From Local Storage</Button>
        <Button on:click={saveToLocalStorage} disabled={!wallet}>Save To Local Storage</Button>
        <hr />

        <Button on:click={async () => (balanceInfo = await getBalance(wallet.address))} disabled={!wallet}>Get Balance</Button>
        {#if balanceInfo}
          <br />
          <p>Balance: {balanceInfo.confirmed}</p>
          <p>Unconfirmed Balance: {balanceInfo.unconfirmed}</p>
        {/if}
        <hr />

        <Input placeholder="Enter target address (BTC or WBTC)" bind:value={targetAddress} />
        <br />
        <Button on:click={async () => await handleSweep(true)} disabled={!wallet || !targetAddress}>Sweep (Dry Run)</Button>
        <Button on:click={async () => await handleSweep(false)} type="is-danger" inverted disabled={!wallet || !targetAddress}>Sweep (Execute)</Button>
        {#if sweepResult}
          <br />
          <pre>{JSON.stringify(sweepResult, stringifyWithBigInts, 2)}</pre>
        {/if}
        <hr />
      </div>
    </div>
  </div>
</div>

<script>
  import { connectedWallet } from '../stores/walletManager'
  import { slide } from 'svelte/transition'
  import { Button, Icon } from 'svelma-fixed'
  import pluralize from 'pluralize'
  import { RouterLink } from '../router'
  import { getThorchainTransactionSimplifiedInfo } from '../lib/walletActions/thorchain'
  import { formatCurrency } from '../lib/utils'
  import services from '../lib/services'
  import { createLoadingStore } from '../stores/loading'
  import { updateThorchainTransaction, handleFollowUpAction } from '../stores/thorchainTxManager'
  import { tick } from 'svelte'

  const completeTransactionLoading = createLoadingStore()
  let completeTransactionLoadingId

  $: augmentedTransactions = $connectedWallet?.thorchainTransactions?.map(tx => ({ ...tx, simple: getThorchainTransactionSimplifiedInfo(tx) })).sort((a, b) => b.simple.timestamp - a.simple.timestamp) ?? []
  $: pendingTransactions = augmentedTransactions.filter(tx => tx.simple.status === 'pending')
  $: completedTransactions = augmentedTransactions.filter(tx => tx.simple.status === 'success' && !tx.wehodlData?.seen)

  const getServiceName = service => services[service]?.name ?? service

  async function completeTransaction (tx) {
    completeTransactionLoadingId = tx.simple.in.txId
    await completeTransactionLoading(async () => {
      await handleFollowUpAction(tx)
    })

    await tick()
    setSeen(augmentedTransactions.find(t => t.simple.in.txId === tx.simple.in.txId)) // Not using setSeen(tx) here because tx is changed by handleFollowUpAction
  }

  function setSeen (tx) {
    updateThorchainTransaction($connectedWallet.address, tx.simple.in.txId, { wehodlData: { ...tx.wehodlData, seen: true } })
  }
</script>

{#if pendingTransactions.length}
  <div class="notification is-link is-light bar" transition:slide|local>
    <div class="container">
      <p>
        <Icon icon="spinner" customClass="fa-pulse" /> There {pendingTransactions.length === 1 ? 'is' : 'are'} <RouterLink to={{ name: 'thorchain' }}>{pluralize('THORChain transaction', pendingTransactions.length, true)}</RouterLink> currently in progress. This can take up to an hour, please be patient.
      </p>
    </div>
  </div>
{/if}

{#each completedTransactions as tx (tx.simple.in.txId)}
  <div class="notification is-success bar" class:is-light={!tx.wehodlData?.followUpAction} transition:slide|local>
    <button class="delete" on:click={() => setSeen(tx)}></button>
    <div class="container">
      <p>
        <Icon icon="check" /> Your THORChain swap of {formatCurrency(tx.simple.in.amount, undefined, -6, '-', true)} {tx.simple.in.symbol} to {formatCurrency(tx.simple.out.amount, undefined, -6, '-', true)} {tx.simple.out.symbol} was successful!
        {#if tx.wehodlData?.followUpAction}
          <strong>Manual action is required to complete your planned {tx.wehodlData?.followUpAction.type} operation on {getServiceName(tx.wehodlData?.followUpAction.service)}.</strong>
        {/if}
      </p>
      {#if tx.wehodlData?.followUpAction}
        <p>
          <Button outlined inverted color="is-success" iconRight="arrow-right" on:click={() => completeTransaction(tx)} loading={$completeTransactionLoading && completeTransactionLoadingId === tx.simple.in.txId} disabled={$completeTransactionLoading && completeTransactionLoadingId !== tx.simple.in.txId}>Complete {tx.wehodlData?.followUpAction.type} operation now</Button>
        </p>
      {/if}
    </div>
  </div>
{/each}

<script>
  import { formatCurrency } from '../lib/utils'
  import thorchainBtcDeposit, { thorchainBtcDepositProcessing } from '../stores/thorchainBtcDeposit'
  import ModalDialog from './ModalDialog.svelte'
  import AutoUpdate from './AutoUpdate.svelte'
  import { Button, Dialog, Toast } from 'svelma-fixed'
  import Loader from './Loader.svelte'

  function formatRemainingTime (until) {
    const remaining = until - Date.now()
    if (remaining <= 0) return '0:00'
    const minutes = Math.floor(remaining / 60000)
    const seconds = Math.floor((remaining % 60000) / 1000)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  async function copyAddress () {
    try {
      await navigator.clipboard.writeText($thorchainBtcDeposit.address)
      Toast.create({ message: 'Address copied to clipboard.', type: 'is-success' })
    } catch (e) {
      console.error('Clipboard writing failed', e)
      Toast.create({ message: 'Clipboard writing failed!', type: 'is-danger' })
    }
  }

  async function cancel () {
    if (!await Dialog.confirm({
      title: 'Cancel Swap',
      message: 'Are you sure you want to cancel the swap? If you already sent any funds, they will not be processed!',
      type: 'is-danger'
    })) return

    $thorchainBtcDeposit.currentTx = null
  }
</script>

{#if $thorchainBtcDeposit.currentTx}
  <ModalDialog large --min-width="800px" --max-width="800px" title="Send BTC for THORChain Swap">
    {#if $thorchainBtcDepositProcessing}
      <Loader inline text="Forwarding your deposit to THORChain..." />
    {:else}
      <p style:word-break="break-word">To complete your swap from BTC to {$thorchainBtcDeposit.currentTx.symbol} using THORChain, please send <strong>{formatCurrency($thorchainBtcDeposit.currentTx.amount, undefined, -6, '-', true)} BTC</strong> to address <strong>{$thorchainBtcDeposit.address}</strong> or use the QR code below:</p>

      <div class="columns mt-3">
        <div class="column is-4 has-text-centered">
          <div class="has-text-centered p-4 is-size-3">
            <AutoUpdate always interval={1000}>{formatRemainingTime($thorchainBtcDeposit.currentTx?.expiration)}</AutoUpdate>
          </div>
          <img src={$thorchainBtcDeposit.currentTx.qrCode} alt="QR code for THORChain BTC deposit" />
          <Button on:click={copyAddress} iconLeft="copy">Copy Address</Button>
        </div>
        <div class="column is-8">
          <p><strong>Important info:</strong></p>
          <ol class="ml-4">
            <li><strong>This wallet does not belong to WEHODL.</strong> It exists only in your browser and it is only temporarily used to forward your funds to THORChain in the special way that is required for THORChain swaps.</li>
            <li><strong>Do not close this page before the funds were received.</strong> Since the wallet exists only in your browser, this page must be kept open for the transaction to be processed.</li>
            <li><strong>Do not send from exchanges.</strong> Exchanges often delay the transaction, and if the funds are sent too late, they may get stuck in the browser wallet or, in the worst case, lost in THORChain. Only send directly from a wallet!</li>
            <li><strong>Do not send with low fee.</strong> Make sure the transaction is confirmed as soon as possible.</li>
            <li><strong>Send the exact amount specified.</strong> All of the amount you send will be attempted to get swapped, but if your amount is much lower than expected, the swap may fail on the THORChain side.</li>
          </ol>
        </div>
      </div>
    {/if}

    <svelte:fragment slot="footer">
      {#if !$thorchainBtcDepositProcessing}
        <Button type="is-danger" on:click={() => cancel()}>Cancel Swap</Button>
      {/if}
    </svelte:fragment>
  </ModalDialog>
{/if}

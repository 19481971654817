<svelte:options accessors />

<script>
  import { getTxStore } from '../stores/txManager'
  import { onDestroy, createEventDispatcher } from 'svelte'
  import { formatError, getAddressShortLabel } from '../lib/utils'
  import uri from 'uri-tag'
  import ModalDialog from './ModalDialog.svelte'
  import { Icon, Button } from 'svelma-fixed'
  import { connectedWalletAddress } from '../stores/walletManager'
  import { ga } from '@beyonk/svelte-google-analytics'
  import { slide } from 'svelte/transition'

  /*
    STEP:
    {
      id,
      status,
      description,
      data, // arbitrary
      waitingForUser, // on status "sending" - true = waiting for signature, false = broadcasting, nullish = preparing
      hash,

      sendTx: async step => {
        // Return hash of tx if it was sent, otherwise return null for skip
      }
    }

    LIFECYCLE:
      - waiting
      - sending
        + sendTx gets called
        + then goes to pending
      - skipped (if sendTx returns null)
      - exception (if sendTx throws)
      - the regular tx statuses: pending, success, failed, dropped, unknown

      exception, failed, dropped, unknown count as failed and enable retry button
      on success or skipped next step is handled, or close button is shown
  */

  export let title = 'Processing transaction'
  export let steps = []
  export let auto = true

  let destroyed = false
  const dispatch = createEventDispatcher()

  const subscriptions = new Map()

  function isStepDone (step) {
    return !step || ['success', 'skipped'].includes(step.status)
  }

  function isStepFailed (step) {
    return !isStepDone(step) && !['waiting', 'sending', 'pending'].includes(step.status)
  }

  $: currentStep = steps.find(step => !isStepDone(step))

  export async function update () {
    const currentStepRef = currentStep

    if (currentStepRef?.status === 'waiting') {
      currentStepRef.status = 'sending'
      currentStepRef.waitingForUser = null

      try {
        const hash = await currentStepRef.sendTx(currentStepRef, function (waitingForUser = true) {
          currentStepRef.waitingForUser = waitingForUser
          steps = steps // notify about change
        })

        if (hash) {
          currentStepRef.status = 'pending'
          currentStepRef.hash = hash
        } else {
          currentStepRef.status = 'skipped'
        }
      } catch (e) {
        console.error('Failed to execute step', currentStepRef, e)
        currentStepRef.status = 'exception'
        currentStepRef.exception = e

        ga.addEvent('error', { operation: 'tx_step', error: formatError(e), address: 'addr:' + $connectedWalletAddress, step: currentStepRef.id, description: currentStepRef.description })
      }

      currentStepRef.waitingForUser = null
      steps = steps // notify about change
    }

    const hash = currentStepRef.hash
    if (!destroyed && currentStep?.status === 'pending' && hash && !subscriptions.has(hash)) {
      subscriptions.set(hash, getTxStore($connectedWalletAddress, hash).subscribe(tx => {
        if (tx && tx.status !== 'pending') {
          const step = steps.find(step => step.hash === tx.hash)
          if (step && step.status === 'pending') {
            step.status = tx.status
            steps = steps // notify about change
          }

          const unsubscribe = subscriptions.get(hash)
          if (unsubscribe) {
            unsubscribe()
            subscriptions.delete(hash)
          }
        }
      }))
    }
  }

  $: if (auto && currentStep) update()

  let prevCurrentStep = currentStep
  $: if (currentStep !== prevCurrentStep) {
    dispatch('stepChanged', currentStep)
    prevCurrentStep = currentStep

    if (!currentStep) dispatch('done')
  }

  onDestroy(() => {
    destroyed = true
    for (const unsubscribe of subscriptions.values()) unsubscribe()
  })

  function close () {
    dispatch('close')
  }

  function retry () {
    currentStep.status = 'waiting'
    currentStep.hash = null
    delete currentStep.exception

    steps = steps // notify about change
  }
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .label {
    font-size: 1.25rem;
    line-height: 1.75;
  }

  .tx {
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 2em auto;
    grid-template-areas:
      'icon title'
      '. subtitle';
    
    @include tablet {
      margin-left: 0.5em;
    }

    .tx-icon {
      grid-area: icon;
    }

    .tx-title {
      grid-area: title;
      font-weight: bold;
    }

    .tx-subtitle {
      grid-area: subtitle;
    }
  }
</style>

<ModalDialog large --min-width="600px" --max-width="600px" on:close title="Processing" closeable>
  <h2 class="label">{title}</h2>

  <div class="steps mb-5">
    {#each steps as step}
      <section class="tx">
        <div class="tx-icon">
          {#if step.status === 'skipped'}
            <span class="has-text-grey"><Icon icon="minus-circle" /></span>
          {:else if step.status === 'sending' || step.status === 'pending'}
            <span><Icon icon="spinner" customClass="fa-pulse" /></span>
          {:else if step.status === 'success'}
            <span class="has-text-success"><Icon icon="check-circle" /></span>
          {:else if isStepFailed(step)}
            <span class="has-text-danger"><Icon icon="times-circle" /></span>
          {/if}
        </div>
        <div class="tx-title" class:has-text-grey={step.status === 'skipped' || step.status === 'waiting'}>
          {step.description}
        </div>
        <div class="tx-subtitle" class:has-text-grey={step.status === 'skipped' || step.status === 'waiting'}>
          {#if step.status === 'waiting'}
            <!-- nothing -->
          {:else if step.status === 'sending'}
            {#if step.waitingForUser === true}
              Waiting for user signature...
            {:else if step.waitingForUser === false}
              Broadcasting transaction...
            {:else}
              Preparing transaction...
            {/if}
          {:else if step.status === 'exception'}
            {#if (step.exception?.error?.code ?? step.exception?.code) === 4001 || (step.exception?.error?.code ?? step.exception?.code) === 'ACTION_REJECTED'}
              Cancelled by user.
            {:else}
              An unexpected error occured: {formatError(step.exception)}
            {/if}
          {:else if step.status === 'pending'}
            Waiting for blockchain confirmations...
            {#if step.hash}
              Hash: <a href={uri`https://etherscan.io/tx/${step.hash}`} target="_blank" rel="noopener noreferrer">{getAddressShortLabel(step.hash)}</a>
            {/if}
          {:else if step.status === 'skipped'}
            Skipped - not required.
          {:else if step.status === 'success'}
            Transaction was successful.
            {#if step.hash}
              Hash: <a href={uri`https://etherscan.io/tx/${step.hash}`} target="_blank" rel="noopener noreferrer">{getAddressShortLabel(step.hash)}</a>
            {/if}
          {:else if step.status === 'failed'}
            Transaction failed!
            {#if step.hash}
              Please <a href={uri`https://etherscan.io/tx/${step.hash}`} target="_blank" rel="noopener noreferrer">check Etherscan</a> for details.
            {/if}
          {:else if step.status === 'dropped'}
            Transaction was dropped!
            {#if step.hash}
              Please <a href={uri`https://etherscan.io/tx/${step.hash}`} target="_blank" rel="noopener noreferrer">check Etherscan</a> for details.
            {/if}
          {:else}
            Transaction is in an unknown state.
          {/if}
        </div>
      </section>
    {:else}
      <p>Nothing to do!</p>
    {/each}
  </div>

  {#if !currentStep}
    {#if steps.some(step => step.data?.service === 'thorchain' && step.data?.type === 'swap' && step.status === 'success')}
      <div class="notification" transition:slide|local style:max-width="600px">
        Your THORChain swap has been initiated. <strong>It can take around one hour until the swap is complete, please be patient.</strong> The amount received will be less than the amount withdrawn due to THORChain fees. For more information see <a href="https://thorchain-university.medium.com/under-the-hood-thorchain-transaction-delays-250d00ed57b7" target="_blank" rel="noopener noreferrer">this article</a>.
      </div>
    {/if}

    <Button class="is-fullwidth" type="is-primary" on:click={close}>Close</Button>
  {:else if isStepFailed(currentStep)}
    <Button class="is-fullwidth" type="is-warning" on:click={retry}>Retry</Button>
  {/if}
</ModalDialog>

import { get } from 'svelte/store'
import { persistentWritable } from './persistentStore'
import { generateBitcoinWallet, getBalance, getFeeRate, getUtxos, importBitcoinWallet, SATOSHI_MULTIPLIER, sweep } from '../lib/bitcoin'
import { getBtcToWbtcQuote, THORCHAIN_MULTIPLIER } from '../lib/walletActions/thorchain'
import { CancelledError } from '../lib/errors'
import { Dialog } from 'svelma-fixed'
import html from 'html-template-tag'
import { formatCurrency, formatPercentage, getNonEthAddressShortLabel } from '../lib/utils'
import { quoteCoin } from './quoteCoin'
import markets from './markets'
import QRCode from 'qrcode'
import uri from 'uri-tag'
import { createLoadingStore } from './loading'
import { upsertThorchainTransaction } from './thorchainTxManager'
import { toChecksumAddress } from 'ethereum-checksum-address'
import services from '../lib/services'

const getServiceName = service => services[service]?.name ?? service

const EXPECTED_TX_SIZE = 200 // vbytes

export const thorchainBtcDeposit = persistentWritable('wehodlThorchainBtcDeposit', { currentTx: null, privateKey: null, address: null, balance: null })
export default thorchainBtcDeposit

export const thorchainBtcDepositProcessing = createLoadingStore()

export async function getBitcoinWallet (createIfNotExisting = false) {
  const { privateKey } = get(thorchainBtcDeposit)
  if (!privateKey) {
    if (createIfNotExisting) {
      return await createBitcoinWallet()
    } else {
      throw new Error('No Bitcoin wallet available')
    }
  } else {
    return await importBitcoinWallet(privateKey)
  }
}

export async function createBitcoinWallet () {
  if (get(thorchainBtcDeposit).privateKey) throw new Error('Bitcoin wallet already exists')

  const wallet = await generateBitcoinWallet()
  const { privateKey, address } = wallet

  if (!await Dialog.confirm({
    title: 'Bitcoin Browser Wallet',
    message: html`To allow the swap (which is a special type of Bitcoin transaction) to work with any regular Bitcoin wallet, the special transaction is created by WEHODL in your browser. For this purpose <strong>a Bitcoin browser wallet has been created for you</strong>. The private key for this wallet is stored only in your browser and is not shared with WEHODL servers.<br><br>Please note that <strong>we do not have access to this wallet</strong>, so please <strong>copy the private key to some safe place now</strong>!<br><br><strong>Private Key:</strong> <pre>${privateKey}</pre><br><strong>Address:</strong> <pre>${address}</pre><br><br>Make sure to save the private key in a secure place. Funds exist on this wallet only temporarily during a swap, but in case something goes wrong, it would be important for you to have access to your private key to be able to recover any stuck funds!`,
    type: 'is-primary',
    size: 'is-large-box',
    icon: 'wallet',
    confirmText: 'OK, I saved my key'
  })) throw new CancelledError('User declined Bitcoin wallet confirmation')

  thorchainBtcDeposit.update($thorchainBtcDeposit => Object.assign($thorchainBtcDeposit, { privateKey, address, balance: null }))

  return wallet
}

export async function initThorchainSwapFromBtc (symbol, amount, address, interactive = false, followUpAction = undefined) {
  if (symbol !== 'WBTC') throw new Error('Invalid symbol for THORChain swap (only WBTC is supported)')

  if (get(thorchainBtcDeposit).currentTx) throw new Error('THORChain BTC deposit already in progress')

  const feeRate = await getFeeRate()
  const fee = Number(feeRate * EXPECTED_TX_SIZE) / SATOSHI_MULTIPLIER
  const amountWithoutFee = amount - fee

  amount = Number(amount).toFixed(8)

  if (amount - fee <= 0) throw new Error('Amount too low for BTC network fee')

  const quote = await getBtcToWbtcQuote(amountWithoutFee, address)
  console.log('THORChain quote', quote)

  if (interactive) {
    const feesRatioTc = quote.fees.total_bps / 10000
    const feesAmountTc = amount * feesRatioTc
    const feesAmountTotal = feesAmountTc + fee
    const feesRatio = feesAmountTotal / amount
    const slippageRatio = quote.fees.slippage_bps / 10000
    const expectedAmountOut = quote.expected_amount_out / THORCHAIN_MULTIPLIER

    const $quoteCoin = get(quoteCoin)
    const feesQuoteCoinAmount = $quoteCoin.ethPrice * get(markets).coins.BTC.price * feesAmountTotal
    if (!feesQuoteCoinAmount) throw new Error('Failed to calculate fees in quote coin')

    if (!await Dialog.confirm({
      title: 'Confirm Swap Fees',
      message: html`This swap from BTC to ${symbol} on THORChain will incur an estimated total of <strong>${formatCurrency(feesQuoteCoinAmount, $quoteCoin.symbol, undefined, '-', true)}</strong> in <a href="https://docs.thorchain.org/how-it-works/fees" target="_blank" rel="noopener noreferrer">swap fees</a> and network fee (${formatPercentage(feesRatio, 0, 2)} of the swap amount). Additionally, up to ${formatPercentage(slippageRatio, 0, 2)} slippage may occur due to possible market movement during the swap process. This will result in a lower amount of ${symbol} being received than the amount of BTC sent.<br><br>Your expected output amount is <strong>${formatCurrency(expectedAmountOut, undefined, -6, '-', true)} ${symbol}</strong>, and by continuing you are agreeing to the <a href="https://www.wehodl.finance/terms-and-conditions" target="_blank" rel="noopener noreferrer">WEHODL terms and conditions</a>. Do you want to proceed with the swap?`,
      type: 'is-primary',
      confirmText: 'Accept & Continue'
    })) throw new CancelledError('User declined swap fee confirmation')
  }

  await getBitcoinWallet(interactive)

  const qrCode = await QRCode.toDataURL(uri`bitcoin:${get(thorchainBtcDeposit).address}?amount=${amount}&message=${'WEHODL THORChain Swap to ' + symbol}`)
  const expiration = Date.now() + 300000 // 5 minutes

  if (expiration > quote.expiry * 1000 - 60000) throw new Error('THORChain quote would expire too early')

  thorchainBtcDeposit.update($thorchainBtcDeposit => Object.assign($thorchainBtcDeposit, { currentTx: { symbol, amount, address, fee, quote, qrCode, expiration, followUpAction } }))
}

let lastUpdate = 0
async function updateThorchainBtcDeposit (force = false) {
  if (updateThorchainBtcDeposit.running) {
    console.warn('Thorchain BTC deposit update already running')
    return
  } else {
    updateThorchainBtcDeposit.running = true
  }

  let balance = null
  try {
    const $thorchainBtcDeposit = get(thorchainBtcDeposit)

    if (!$thorchainBtcDeposit.privateKey) return
    if (!force && Date.now() - lastUpdate < ($thorchainBtcDeposit.currentTx ? 10000 : 600000)) return

    lastUpdate = Date.now()

    const wallet = await getBitcoinWallet()

    const utxos = await getUtxos(wallet.address)
    const { confirmedSat, unconfirmedSat } = await getBalance(wallet.address, utxos)

    balance = Number(confirmedSat + unconfirmedSat) / SATOSHI_MULTIPLIER

    if ($thorchainBtcDeposit.currentTx && Date.now() >= $thorchainBtcDeposit.currentTx.expiration) {
      Dialog.alert({
        title: 'Swap Expired',
        message: 'The THORChain swap has expired and no BTC funds were deposited. Please initiate a new swap.',
        type: 'is-danger',
        icon: 'exclamation-circle'
      })
      thorchainBtcDeposit.update($thorchainBtcDeposit => Object.assign($thorchainBtcDeposit, { currentTx: null }))
      return
    }

    if (confirmedSat + unconfirmedSat <= 0n || !$thorchainBtcDeposit.currentTx) return

    let doRetry = false
    await thorchainBtcDepositProcessing(async () => {
      try {
        const currentTx = $thorchainBtcDeposit.currentTx
        const quote = currentTx.quote

        const txRecipient = quote.inbound_address
        const opReturnData = Buffer.from(quote.memo, 'utf-8')

        const sweepResult = await sweep(wallet.address, wallet.privateKey, txRecipient, { dryRun: false, opReturnData, utxos })
        console.log('Sweep result', sweepResult)

        thorchainBtcDeposit.update($thorchainBtcDeposit => Object.assign($thorchainBtcDeposit, { currentTx: null }))
        balance = 0

        const thorchainInTxId = sweepResult.txId.toUpperCase()
        try {
          upsertThorchainTransaction(toChecksumAddress(currentTx.address), thorchainInTxId, {
            type: 'swap',
            in: [{
              address: wallet.address,
              coins: [{
                amount: quote._params.amount,
                asset: quote._params.from_asset
              }],
              txID: thorchainInTxId
            }],
            metadata: {
              swap: {
                memo: quote.memo,
                txType: 'swap'
              }
            },
            out: null,
            status: null,
            wehodlData: { expectedOutAmount: quote.expected_amount_out / THORCHAIN_MULTIPLIER, followUpAction: currentTx.followUpAction }
          })

          Dialog.alert({
            title: 'Swap Initiated',
            message: html`
              <p class="mb-3">Your BTC funds have been successfully deposited to THORChain for swapping to ${currentTx.symbol}.</p>
              $${currentTx.followUpAction ? html`<p class="mb-3">After the swap has completed, please come back to finish your planned ${currentTx.followUpAction.followUpAction.type} operation on ${getServiceName(currentTx.followUpAction.service)}!</p>` : ''}
              <p>Transaction ID: <a href=${uri`https://blockchair.com/bitcoin/transaction/${sweepResult.txId}`} target="_blank" rel="noopener noreferrer">${getNonEthAddressShortLabel(sweepResult.txId)}</a></p>
            `,
            type: 'is-success',
            icon: 'check-circle'
          })
        } catch (e) {
          console.error('Failed to upsert THORChain transaction', e)
        }
      } catch (e) {
        console.error('Failed to sweep BTC funds to THORChain', e)
        const retry = await Dialog.confirm({
          message: html`
            <p class="mb-3">
              Something went wrong while submitting your THORChain swap transaction:
            </p>
            <p class="mb-3">
              ${e.serverErrorMessage ?? e.message}
            </p>
            <p>
              <strong>Do you want to retry the transaction?</strong> (If you don't, your funds will need to be swept from the browser wallet before you can try again.)
            </p>
          `,
          type: 'is-warning',
          icon: 'exclamation-triangle',
          confirmText: 'Retry',
          cancelText: 'Cancel'
        })
        if (retry) {
          doRetry = true
        } else {
          thorchainBtcDeposit.update($thorchainBtcDeposit => Object.assign($thorchainBtcDeposit, { currentTx: null }))
          throw e
        }
      }
    })

    if (doRetry) {
      console.log('Retrying THORChain BTC deposit update...')
      updateThorchainBtcDeposit.running = false
      await updateThorchainBtcDeposit(true)
    }
  } catch (e) {
    lastUpdate = Date.now() - 540000
    throw e
  } finally {
    updateThorchainBtcDeposit.running = false

    if (balance !== null) {
      thorchainBtcDeposit.update($thorchainBtcDeposit => Object.assign($thorchainBtcDeposit, { balance }))
    }
  }
}

export function initThorchainBtcDepositUpdate () {
  updateThorchainBtcDeposit(true)
  setInterval(updateThorchainBtcDeposit, 5000)
}

<script>
  import ErrorBox from '../components/ErrorBox.svelte'
  import LiquidationCalculator from '../components/LiquidationCalculator.svelte'
  import { softNavigate } from '../router'
  import { Dialog, Snackbar } from 'svelma-fixed'
  import { onMount, tick } from 'svelte'
  import markets from '../stores/markets'
  import { activeWalletAddress, mode, searchWallet, unlinkConnectedWallet, unlinkImportedWallet, walletAutoConnecting, connectedWallet, walletConnecting, updateLiveData, switchMode, connectBlocknative } from '../stores/walletManager'
  import { combineLoadingStores, createLoadingStore } from '../stores/loading'
  import html from 'html-template-tag'
  import Loader from '../components/Loader.svelte'
  import ThorchainNotificationBars from '../components/ThorchainNotificationBars.svelte'

  const urlParamsLoading = createLoadingStore()
  const loading = combineLoadingStores(urlParamsLoading, walletAutoConnecting)
  export let addressFromUrl
  export let isManage = false
  const addressFromUrlInitial = addressFromUrl
  if (addressFromUrl && $activeWalletAddress !== addressFromUrlInitial) {
    unlinkImportedWallet()
    urlParamsLoading(async () => {
      try {
        await searchWallet(addressFromUrlInitial)

        Snackbar.create({ message: 'Address loaded successfully.', type: 'is-success', duration: 10000 })
      } catch (e) {
        Dialog.alert({
          message: html`
            <p class="mb-3">
              The wallet address <strong>${addressFromUrlInitial}</strong> could not be loaded!
            </p>
            <p>
              ${e.serverErrorMessage ?? e.message}
            </p>
          `,
          type: 'is-danger',
          icon: 'exclamation-circle'
        })
      }
    })
  } else if (isManage && $mode === 'readOnly') {
    urlParamsLoading(async () => {
      if (!$connectedWallet && !$walletConnecting) {
        await connectBlocknative(true)
      } else if ($connectedWallet && $mode === 'readOnly') {
        switchMode('blocknative')
        await updateLiveData()
      }
    })
  } else if (!isManage && $mode !== 'readOnly') {
    switchMode('readOnly')
  }

  $: if (!$urlParamsLoading) {
    const targetParams = {
      isManage: $mode !== 'readOnly',
      address: $mode !== 'readOnly' ? null : ($activeWalletAddress || null)
    }

    if (targetParams.isManage !== (isManage || false) || (!isManage && targetParams.address !== (addressFromUrl || null))) {
      if (targetParams.isManage) {
        softNavigate({ name: 'manage' }, 'replace')
      } else {
        softNavigate({ name: 'calculator', params: { address: targetParams.address } }, 'replace')
      }
    }
  }

  let initialized = false
  let loadCalculator = false

  onMount(() => {
    tick().then(() => (loadCalculator = true))
  })

  function resetState () {
    unlinkImportedWallet()
    unlinkConnectedWallet()
    localStorage.removeItem('wehodlCalculatorState')
    location.reload()
  }
</script>

<svelte:head>
  <title>DeFi Borrow-Liquidation Calculator - WEHODL</title>

  <style>
    html {
      scroll-behavior: smooth;
    }
  </style>
</svelte:head>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  @include mobile {
    .calculator-box {
      box-shadow: none;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .is-max-desktop { // Normally the max-width is 960px but we want it even smaller. Note: 768 is also divisible by 12 and 16, like 960 is.
    @media screen and (min-width: 832px) {
      max-width: 768px;
    }
  }

  .container {
    padding: 0 !important;
  }
</style>

{#if $loading}
  <Loader />
{:else if $markets}
  <ThorchainNotificationBars />

  <div class="container is-max-desktop pt-5">
    <div class="box my-4 calculator-box">
      {#if !initialized}
        <p class="m-5">If the calculator seems to be broken, <a href={undefined} on:click={resetState}><strong>click here</strong></a> to reset it and try again.</p>
      {/if}
      {#if loadCalculator}
        <LiquidationCalculator on:init={() => (initialized = true)} />
      {/if}
    </div>
  </div>
{:else}
  <ErrorBox>There is currently no market data available - please try again later!</ErrorBox>
{/if}

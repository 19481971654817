<script>
  import { slide } from 'svelte/transition'
  import thorchainBtcDeposit, { getBitcoinWallet } from '../stores/thorchainBtcDeposit'
  import { Button, Dialog, Icon } from 'svelma-fixed'
  import { formatCurrency, getNonEthAddressShortLabel } from '../lib/utils'
  import { createLoadingStore } from '../stores/loading'
  import { sweep } from '../lib/bitcoin'
  import html from 'html-template-tag'
  import uri from 'uri-tag'

  const loading = createLoadingStore()

  async function withdraw () {
    await loading(async () => {
      const btcAddress = await Dialog.prompt({
        title: 'Transfer Funds',
        message: 'Please enter your Bitcoin address to transfer your browser wallet funds to:',
        inputProps: {
          type: 'text',
          placeholder: 'BTC Address',
          required: true
        },
        confirmText: 'Transfer Now',
        cancelText: 'Cancel',
        showCancel: true
      })

      if (!btcAddress) return

      try {
        const wallet = await getBitcoinWallet()
        const sweepResult = await sweep(wallet.address, wallet.privateKey, btcAddress, { dryRun: false })
        console.log('Sweep result', sweepResult)

        $thorchainBtcDeposit.balance = 0
        Dialog.alert({
          title: 'Funds Transferred',
          message: html`
            <p class="mb-3">The funds from your Bitcoin browser wallet have been transferred.</p>
            <p>Transaction ID: <a href=${uri`https://blockchair.com/bitcoin/transaction/${sweepResult.txId}`} target="_blank" rel="noopener noreferrer">${getNonEthAddressShortLabel(sweepResult.txId)}</a></p>
          `,
          type: 'is-success',
          icon: 'check-circle'
        })
      } catch (e) {
        console.error('Failed to sweep funds', e)
        Dialog.alert({
          message: html`
            <p class="mb-3">
              Something went wrong:
            </p>
            <p>
              ${e.serverErrorMessage ?? e.message}
            </p>
          `,
          type: 'is-danger',
          icon: 'exclamation-circle'
        })
      }
    })
  }
</script>

{#if $thorchainBtcDeposit.balance > 0}
  <div class="notification is-danger bar" transition:slide|local>
    <div class="container">
      <p>
        <Icon icon="exclamation-triangle" /> Your Bitcoin browser wallet has a balance of <strong>{formatCurrency($thorchainBtcDeposit.balance, undefined, -6, '-', true)} BTC</strong>! <strong>The browser wallet is not safe for permanent storage of funds.</strong> The browser wallet is supposed to be used only temporarily during a swap. Please transfer your funds to a secure wallet as soon as possible.
      </p>
      <p>
        <Button outlined inverted color="is-danger" iconRight="arrow-right" on:click={withdraw} loading={$loading}>Transfer Funds Now</Button>
      </p>
    </div>

  </div>
{/if}

import { derived, get } from 'svelte/store'
import { connectedWallet, connectedWalletAddress, registeredWallets } from './walletManager'
import AsyncLock from 'async-lock'
import { getThorchainTransactionSimplifiedInfo, loadAddressHistory } from '../lib/walletActions/thorchain'
import globalComponents from '../lib/globalComponents'
import { switchService } from './calculatorState'
import { tick } from 'svelte'

import devOptions from './devOptions'

// TODO: Remove this again once the feature is launched
if (new URLSearchParams(window.location.search).get('enableThorchain') === '1') {
  devOptions.update($devOptions => {
    $devOptions.featureFlags.thorchainSupport = true
    return $devOptions
  })
}

const lock = new AsyncLock()

export function getThorchainTransactionFromWalletObject (wallet, hash, allowUndefinedWallet = false) {
  const thorchainTransactions = wallet?.thorchainTransactions
  if (!thorchainTransactions) {
    if (allowUndefinedWallet) return undefined
    throw new Error('Wallet address not found')
  }
  return thorchainTransactions.find(tx => tx.in[0]?.txID === hash)
}

export function getThorchainTransaction (walletAddress, hash) {
  return getThorchainTransactionFromWalletObject(get(registeredWallets)[walletAddress], hash)
}

export function updateThorchainTransaction (walletAddress, hash, data) {
  registeredWallets.update($registeredWallets => {
    const tx = getThorchainTransactionFromWalletObject($registeredWallets[walletAddress], hash)
    if (!tx) throw new Error('Transaction not found')
    Object.assign(tx, data)
    return $registeredWallets
  })
}

function buildThorchainTransaction (hash, data) {
  return { in: [{ txID: hash }], date: String(Date.now()) + '000000', wehodlData: {}, ...data }
}

export function upsertThorchainTransaction (walletAddress, hash, data) {
  if (!hash) hash = data.in[0]?.txID
  if (!hash) throw new Error('No TX ID for THORChain transaction')
  registeredWallets.update($registeredWallets => {
    const thorchainTransactions = $registeredWallets[walletAddress]?.thorchainTransactions
    if (!thorchainTransactions) throw new Error('Wallet address not found')
    const tx = thorchainTransactions.find(tx => tx.in[0]?.txID === hash)
    if (tx) {
      Object.assign(tx, data)
    } else {
      thorchainTransactions.push(buildThorchainTransaction(hash, data))
    }
    return $registeredWallets
  })
}

export function getThorchainTxStore (walletAddress, hash) {
  return derived(registeredWallets, $registeredWallets => getThorchainTransactionFromWalletObject($registeredWallets[walletAddress], hash, true))
}

export async function updateWalletThorchainTransactions (walletAddress) {
  await lock.acquire(walletAddress, async () => {
    const thorchainTransactions = get(registeredWallets)[walletAddress]?.thorchainTransactions
    if (!thorchainTransactions) throw new Error('Wallet address not found')

    const actions = await loadAddressHistory(walletAddress, 25, { type: 'swap' })

    registeredWallets.update($registeredWallets => {
      const thorchainTransactions = $registeredWallets[walletAddress]?.thorchainTransactions
      if (!thorchainTransactions) throw new Error('Wallet address not found')

      const seen = new Set()
      for (const action of actions) {
        const hash = action.in[0]?.txID
        if (!hash) continue
        seen.add(hash)

        const tx = thorchainTransactions.find(tx => tx.in[0]?.txID === hash)
        if (tx) {
          Object.assign(tx, action)
        } else {
          const tx = buildThorchainTransaction(hash, action)
          if (getThorchainTransactionSimplifiedInfo(tx).status === 'success') tx.wehodlData.seen = true
          thorchainTransactions.push(tx)
        }
      }

      $registeredWallets[walletAddress].thorchainTransactions = thorchainTransactions.filter(tx => {
        const hash = tx.in[0]?.txID
        if (!hash) return false
        if (seen.has(hash)) return true
        if (tx.status) {
          return tx.date / 1e6 > Date.now() - 24 * 3600000
        } else {
          return tx.date / 1e6 > Date.now() - 3600000
        }
      })

      return $registeredWallets
    })
  })
}

export async function handleFollowUpAction (tx) {
  const followUpAction = tx.wehodlData.followUpAction
  if (!followUpAction) return

  const simple = tx.simple ?? getThorchainTransactionSimplifiedInfo(tx)

  await globalComponents.nav.manage()

  await tick()
  await tick()

  switchService(followUpAction.service)

  await tick()
  await tick()

  const data = { ...followUpAction.data }
  if (data.amount && data.symbol === simple.out.symbol) {
    data.amount = simple.out.amount

    const balance = get(connectedWallet)?.liveData?.balances.find(b => b.symbol === data.symbol)?.amount ?? 0
    if (balance >= data.amount * 0.9999 && balance <= data.amount * 1.0001) {
      data.amount = balance
      data.all = true
    }
  }

  globalComponents.walletActions[followUpAction.assetType].open(followUpAction.type, data, followUpAction.props)

  upsertThorchainTransaction(get(connectedWalletAddress), simple.in.txId, { wehodlData: { ...tx.wehodlData, followUpAction: null } })
}

let initialized = false
export function initThorchainTxManager () {
  if (initialized) return

  let lastChecked = 0

  let $connectedWalletAddress
  connectedWalletAddress.subscribe(newConnectedWalletAddress => {
    $connectedWalletAddress = newConnectedWalletAddress
    lastChecked = 0

    if ($connectedWalletAddress) {
      lastChecked = Date.now()
      updateWalletThorchainTransactions($connectedWalletAddress).catch(e => console.error('Failed to check THORChain transactions on active wallet change', e))
    }
  })

  setInterval(() => {
    if ($connectedWalletAddress) {
      if (lastChecked > Date.now() - 120000 && !get(connectedWallet).thorchainTransactions?.some(tx => !tx.status || (tx.type === 'swap' && tx.status === 'success' && !tx.out.some(out => out.coins.some(c => c.asset === tx.metadata.swap?.memo?.split(':')[1]))))) return

      if (lock.isBusy($connectedWalletAddress)) return
      lastChecked = Date.now()
      updateWalletThorchainTransactions($connectedWalletAddress).catch(e => console.error('Failed to check THORChain transactions in background', e))
    }
  }, 30000)

  initialized = true
}

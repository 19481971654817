import { getAllowance, getAmountInSmallestUnit, getApproveStep, getErc20Balance, verifyMainnetChain, waitForTxHashOnly } from '.'
import * as ethers from 'ethers'
import { currentProvider, toChecksumAddress } from '../../stores/walletManager'
import { formatCurrency, formatPercentage } from '../utils'
import { getTxStatusByHash, upsertTransaction } from '../../stores/txManager'
import markets from '../../stores/markets'
import { get } from 'svelte/store'
import { quoteCoin } from '../../stores/quoteCoin'
import { selectedService } from '../../stores/calculatorState'
import { Dialog } from 'svelma-fixed'
import { CancelledError } from '../errors'
import html from 'html-template-tag'
import { upsertThorchainTransaction } from '../../stores/thorchainTxManager'

let provider

currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
})

async function verifyPreconditions () {
  await verifyMainnetChain()
  if (!provider) throw new Error('Provider unavailable')
}

export const THORCHAIN_MULTIPLIER = 1e8

export async function getQuote (fromAsset, toAsset, amount, targetAddress) {
  const inboundAddressesResponse = await fetch(`${window.appVariables.thorchainConfig.nodeUrl}/thorchain/inbound_addresses`)
  const inboundAddresses = await inboundAddressesResponse.json()
  const fromChain = fromAsset.split(/[./~]/)[0]
  const toChain = toAsset.split(/[./~]/)[0]
  const fromInfo = inboundAddresses.find?.(address => address.chain === fromChain)
  const toInfo = inboundAddresses.find?.(address => address.chain === toChain)
  if (!fromInfo || !toInfo) throw new Error('THORChain chain data not found')
  if (fromInfo.halted || toInfo.halted) throw new Error('THORChain trading is currently halted')

  const params = {
    from_asset: fromAsset,
    to_asset: toAsset,
    amount: String(Math.floor(amount * THORCHAIN_MULTIPLIER)),
    destination: targetAddress,
    streaming_interval: 1,
    streaming_quantity: 0,
    ...window.appVariables.thorchainConfig.affiliateName && window.appVariables.thorchainConfig.affiliateFeePercent > 0
      ? {
          affiliate_bps: Math.round(window.appVariables.thorchainConfig.affiliateFeePercent * 100),
          affiliate: window.appVariables.thorchainConfig.affiliateName
        }
      : {}
  }
  const response = await fetch(`${window.appVariables.thorchainConfig.nodeUrl}/thorchain/quote/swap?${new URLSearchParams(params)}`)
  if (!response.ok) throw new Error(`Failed to get THORChain quote: ${await response.text()}`)
  const quote = await response.json()
  quote._params = params

  if (quote.error) {
    if (quote.error?.includes('not enough asset to pay for fees')) {
      throw new Error('THORChain swap is not possible because the amount is too low to pay for fees!')
    } else {
      throw new Error(`THORChain quote error: ${quote.error}`)
    }
  }

  return quote
}

export async function getWbtcToBtcQuote (amount, targetAddress) {
  const symbol = 'WBTC'
  const fromAsset = `ETH.${symbol}-${get(markets).coins[symbol].tokenAddress.toUpperCase()}`
  const toAsset = 'BTC.BTC'
  return getQuote(fromAsset, toAsset, amount, targetAddress)
}

export async function getBtcToWbtcQuote (amount, targetAddress) {
  const symbol = 'WBTC'
  const fromAsset = 'BTC.BTC'
  const toAsset = `ETH.${symbol}-${get(markets).coins[symbol].tokenAddress.toUpperCase()}`
  return getQuote(fromAsset, toAsset, amount, targetAddress)
}

export async function getThorchainRouterContract (address) {
  const routerContract = await new ethers.Contract(address, ['function depositWithExpiry(address,address,uint256,string,uint256)'], provider.getSigner())
  return routerContract
}

export async function getWbtcToBtcSwapSteps (amount, targetAddress, interactive = false) {
  const symbol = 'WBTC'

  if (amount <= 0) return []
  await verifyPreconditions()

  // Expiration of quotes is usually around 15 minutes. If the first steps would ever take longer, it could be a problem, and we'd have to
  // change the code to fetch the quote at the time of actual step execution.

  const quote = await getWbtcToBtcQuote(amount, targetAddress)

  if (interactive) {
    const feesRatio = quote.fees.total_bps / 10000
    const slippageRatio = quote.fees.slippage_bps / 10000
    const expectedAmountOut = quote.expected_amount_out / THORCHAIN_MULTIPLIER

    const $quoteCoin = get(quoteCoin)
    const feesQuoteCoinAmount = $quoteCoin.ethPrice * get(markets).coins[symbol].services[get(selectedService)]?.price * amount * feesRatio
    if (!feesQuoteCoinAmount) throw new Error('Failed to calculate fees in quote coin')

    if (!await Dialog.confirm({
      title: 'Confirm Swap Fees',
      message: html`On top of gas cost, this swap from ${symbol} to BTC on THORChain will incur an estimated total of <strong>${formatCurrency(feesQuoteCoinAmount, $quoteCoin.symbol, undefined, '-', true)}</strong> in <a href="https://docs.thorchain.org/how-it-works/fees" target="_blank" rel="noopener noreferrer">swap fees</a> (${formatPercentage(feesRatio, 0, 2)} of the swap amount). Additionally, up to ${formatPercentage(slippageRatio, 0, 2)} slippage may occur due to possible market movement during the swap process. This will result in a lower amount of BTC being received than the amount of ${symbol} sent.<br><br>Your expected output amount is <strong>${formatCurrency(expectedAmountOut, undefined, -6, '-', true)} BTC</strong>, and by continuing you are agreeing to the <a href="https://www.wehodl.finance/terms-and-conditions" target="_blank" rel="noopener noreferrer">WEHODL terms and conditions</a>. Do you want to proceed with the swap?`,
      type: 'is-primary',
      confirmText: 'Accept & Continue'
    })) throw new CancelledError('User declined swap fee confirmation')
  }

  const steps = []

  let amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)

  if (ethers.BigNumber.from(await getAllowance(symbol, quote.router)).lt(ethers.BigNumber.from(amountInSmallestUnit))) {
    steps.push(getApproveStep(symbol, quote.router, 'thorchain', `Approve ${symbol} debit by THORChain router`, `Approve ${symbol} debit by THORChain router`))
  }

  const balance = await getErc20Balance(symbol)
  // Allow small discrepancies which may occur when withdrawing ALL funds
  if (balance.lt(ethers.BigNumber.from(amountInSmallestUnit)) && Number(balance) / Number(amountInSmallestUnit) > 0.999) {
    amountInSmallestUnit = String(balance)
  }

  const routerContract = await getThorchainRouterContract(quote.router)
  // depositWithExpiry(vault, asset, amount, memo, expiry)
  steps.push({
    id: 'swap',
    description: `Swap ${formatCurrency(amount, undefined, -6, '-', true)} ${symbol} to BTC on THORChain`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (Date.now() > quote.expiry * 1000 - 60000) throw new Error(`THORChain quote expired, please swap ${symbol} to BTC manually`)

      const hash = await waitForTxHashOnly(routerContract.depositWithExpiry(quote.inbound_address, get(markets).coins[symbol].tokenAddress, amountInSmallestUnit, quote.memo, Math.floor((Date.now() + 3600000) / 1000)), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'thorchain',
          type: 'swap',
          symbol,
          amount
        },
        icon: 'exchange',
        description: `Swap ${formatCurrency(amount, undefined, -6, '-', true)} ${symbol} to BTC on THORChain`,
        status,
        nonce: Number(tx.nonce)
      })

      const thorchainInTxId = hash.slice(2).toUpperCase()
      upsertThorchainTransaction(toChecksumAddress(tx.from), thorchainInTxId, {
        type: 'swap',
        in: [{
          address: tx.from.toLowerCase(),
          coins: [{
            amount: quote._params.amount,
            asset: quote._params.from_asset
          }],
          txID: thorchainInTxId
        }],
        metadata: {
          swap: {
            memo: quote.memo,
            txType: 'swap'
          }
        },
        out: null,
        status: null,
        wehodlData: { expectedOutAmount: quote.expected_amount_out / THORCHAIN_MULTIPLIER }
      })

      return hash
    }
  })

  return steps
}

export async function loadAddressHistory (address, limit = 10, options = {}) {
  const response = await fetch(`${window.appVariables.thorchainConfig.midgardUrl}/v2/actions?${new URLSearchParams({ address, limit, ...options })}`, {
    headers: {
      'X-Client-ID': `wehodl.finance:client:user:${address}`
    }
  })

  if (!response.ok) throw new Error(`Failed to load THORChain address history: ${await response.text()}`)

  const { actions } = await response.json()

  return actions
}

export function getThorchainAssetSymbol (assetId) {
  return assetId?.split(/[./~]/)[1]?.split('-')[0] ?? '???'
}

export function getThorchainTransactionSimplifiedInfo (tx) {
  if (tx.type !== 'swap') throw new Error('Cannot get simplified info from non-swap THORChain TX')

  const [, outAssetIdShort, outAddress] = tx.metadata.swap.memo.split(':')

  const info = {
    timestamp: Math.floor(tx.date / 1e6),
    in: {
      txId: tx.in[0].txID,
      address: tx.in[0].address
    },
    out: {
      address: outAddress,
      assetId: outAssetIdShort,
      symbol: getThorchainAssetSymbol(outAssetIdShort),
      amount: tx.wehodlData?.expectedOutAmount
    },
    status: tx.status ?? 'pending'
  }

  if (tx.in[0].coins[0]) {
    info.in = {
      ...info.in,
      assetId: tx.in[0].coins[0].asset,
      symbol: getThorchainAssetSymbol(tx.in[0].coins[0].asset),
      amount: tx.in[0].coins[0].amount / THORCHAIN_MULTIPLIER
    }
  }

  const out = tx.out?.find(o => o.coins[0]?.asset?.split('-')[0] === outAssetIdShort.split('-')[0] && o.address?.toLowerCase() === outAddress.toLowerCase())
  if (out) {
    info.out = {
      ...info.out,
      txId: out.txID,
      amount: out.coins[0].amount / THORCHAIN_MULTIPLIER
    }
  }

  if (!out && info.status === 'success') {
    info.status = 'pending'
  }

  return info
}

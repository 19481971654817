import { ALL, MAX_UINT256, approve, getAllowance, getAmountForDisplay, getAmountInSmallestUnit, getApproveStep, getDecimals, getErc20Balance, verifyMainnetChain, waitForTxHashOnly } from '.'
import { connectedWalletAddress, toChecksumAddress, currentProvider } from '../../stores/walletManager'
import { getTxStatusByHash, upsertTransaction } from '../../stores/txManager'
import { get } from 'svelte/store'
import dialogs from '../../stores/dialogs'
import RepayPriorityPrompt from '../../components/RepayPriorityPrompt.svelte'
import markets from '../../stores/markets'
import * as ethers from 'ethers'
import { formatCurrency } from '../utils'
import * as aave from '@aave/protocol-js'
import aaveV2ProtocolDataProviderAbi from '../abi/aaveV2ProtocolDataProviderAbi.json'
import aaveV2LendingPoolAbi from '../abi/aaveV2LendingPoolAbi.json'
import aaveV2AddressProviderAbi from '../abi/aaveV2AddressProviderAbi.json'

const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'

let provider
export let aaveTxBuilder
export let aaveLendingPoolRegular
export let aaveLendingPoolAmm
export const getAaveLendingPoolForAsset = symbol => symbol.startsWith('Amm') ? aaveLendingPoolAmm : aaveLendingPoolRegular

const aaveReferralCode = Number(window.appVariables.aaveV2ReferralCode) || 0

currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
  aaveTxBuilder = $currentProvider && new aave.TxBuilderV2(aave.Network.mainnet, $currentProvider)
  aaveLendingPoolRegular = aaveTxBuilder?.getLendingPool(aave.Market.Proto)
  aaveLendingPoolAmm = aaveTxBuilder?.getLendingPool(aave.Market.AMM)
})

async function sendTransaction (txData) {
  const fixedTxData = { ...txData }

  // Without this fix, some data that has a decimal as string can get misinterpreted as hex!
  // Also, get rid of any leading zeroes in hex strings because they cause an error in Infura:
  // "invalid argument 0: json: cannot unmarshal hex number with leading zero digits into Go struct field TransactionArgs.value of type *hexutil.Big"
  // This error would result in MetaMask showing a problem in estimating gas limit and using an extremely high gas limit instead.
  for (const key of ['value', 'gasPrice', 'gasLimit', 'nonce', 'chainId']) {
    if (fixedTxData[key] != null) fixedTxData[key] = ethers.BigNumber.from(fixedTxData[key]).toHexString().replace(/^0x0+/, '0x').replace(/^0x$/, '0x0')
  }

  console.log('Sending TX', fixedTxData)
  return await provider.send('eth_sendTransaction', [fixedTxData])
}

async function verifyPreconditions () {
  await verifyMainnetChain()
  if (!aaveTxBuilder) throw new Error('SDK unavailable')
}

async function getLendingPoolContract (symbol) {
  if (!provider) throw new Error('Ethereum provider is not available')

  const { lendingPoolAddress } = getAaveLendingPoolForAsset(symbol)
  const contract = await new ethers.Contract(lendingPoolAddress, aaveV2LendingPoolAbi, provider.getSigner())
  return contract
}

async function getAddressProviderContract (symbol) {
  const lendingPool = await getLendingPoolContract(symbol)
  const addressProviderAddress = await lendingPool.getAddressesProvider()
  const contract = await new ethers.Contract(addressProviderAddress, aaveV2AddressProviderAbi, provider.getSigner())
  return contract
}

async function getProtocolDataProviderContract (symbol) {
  const addressProvider = await getAddressProviderContract(symbol)
  // For some reason, the provider is on 0x01 for main but on 0x10 for AMM
  let protocolDataProviderAddress = await addressProvider.getAddress('0x01'.padEnd(66, '0'))
  if (protocolDataProviderAddress === NULL_ADDRESS) protocolDataProviderAddress = await addressProvider.getAddress('0x10'.padEnd(66, '0'))
  if (protocolDataProviderAddress === NULL_ADDRESS) throw new Error(`No protocol data provider address for ${symbol} from address provider ${addressProvider.address}`)
  const contract = await new ethers.Contract(protocolDataProviderAddress, aaveV2ProtocolDataProviderAbi, provider.getSigner())
  return contract
}

export async function aaveV2GetUserReserveData (symbol) {
  const protocolDataProvider = await getProtocolDataProviderContract(symbol)

  const contractAddress = get(markets).coins[symbol]?.tokenAddress
  if (!contractAddress) throw new Error(`No contract address found for ${symbol}`)

  return await protocolDataProvider.getUserReserveData(contractAddress, get(connectedWalletAddress))
}

export async function aaveV2IsCollateralEnabled (symbol) {
  const { usageAsCollateralEnabled } = await aaveV2GetUserReserveData(symbol)

  return Number(usageAsCollateralEnabled) === 1
}

export async function aaveV2ActionEnableDisable (symbol, enabled) {
  await verifyPreconditions()

  const contractAddress = get(markets).coins[symbol === 'ETH' ? 'WETH' : symbol]?.tokenAddress
  if (!contractAddress) throw new Error(`No contract address found for ${symbol}`)

  const getTxs = async () => await getAaveLendingPoolForAsset(symbol).setUsageAsCollateral({
    user: get(connectedWalletAddress),
    reserve: contractAddress,
    usageAsCollateral: enabled
  })

  const txs = await getTxs()
  console.log('Aave enable/disable TX result array:', txs)

  return [{
    id: enabled ? 'enable' : 'disable',
    description: 'Update collateral usage state',
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (await aaveV2IsCollateralEnabled(symbol) === enabled) {
        return null
      } else {
        const txs = await getTxs()
        const txData = await txs.find(tx => tx.txType === 'DLP_ACTION')?.tx()
        if (!txData) return null

        const hash = await waitForTxHashOnly(sendTransaction(txData), setWaitingForUser)

        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV2',
            type: enabled ? 'enable' : 'disable',
            symbol
          },
          icon: 'power-off',
          description: `${enabled ? 'Enable' : 'Disable'} ${symbol} for use in Aave V2`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    }
  }]
}

export function getAaveV2ApproveStep (symbol, getTxs) {
  return getApproveStep(symbol, async () => {
    const txs = await getTxs()
    const txData = await txs.find(tx => tx.txType === 'ERC20_APPROVAL')?.tx()
    if (!txData) return null

    return {
      promise: sendTransaction(txData)
    }
  }, 'aaveV2')
}

export async function getWEthGatewayApproveStep (symbol, tokenAddress, amountInSmallestUnit) {
  const { wethGatewayService } = getAaveLendingPoolForAsset('WETH')
  if (ethers.BigNumber.from(await getAllowance(tokenAddress, wethGatewayService.wethGatewayAddress)).lt(ethers.BigNumber.from((amountInSmallestUnit * (10 ** aave.ETH_DECIMALS)).toFixed(0)))) {
    return getApproveStep(symbol, async () => ({ promise: approve(tokenAddress, wethGatewayService.wethGatewayAddress) }), 'aaveV2', 'Approve WETH gateway access', 'Approve Aave V2 WETH gateway access for withdrawal')
  } else {
    return undefined
  }
}

export async function getWEthGatewayDelegationStep (symbol, tokenAddress, amount) {
  const { wethGatewayService } = getAaveLendingPoolForAsset('WETH')
  const debtTokenContract = wethGatewayService.baseDebtTokenService.contractFactory.connect(tokenAddress, provider.getSigner())
  const borrowAllowance = await debtTokenContract.borrowAllowance(get(connectedWalletAddress), wethGatewayService.wethGatewayAddress)
  if (ethers.BigNumber.from(borrowAllowance).lt(ethers.BigNumber.from((amount * (10 ** aave.ETH_DECIMALS)).toFixed(0)))) {
    return getApproveStep(symbol, async () => ({ promise: debtTokenContract.approveDelegation(wethGatewayService.wethGatewayAddress, MAX_UINT256) }), 'aaveV2', 'Approve WETH gateway access', 'Approve Aave V2 WETH gateway access for borrowing')
  } else {
    return undefined
  }
}

export async function scaleToTemporaryBigNumber (symbol, amount) {
  return new aave.BigNumber(String(amount)).dividedBy(new aave.BigNumber(10).pow(await getDecimals(symbol))).toString()
}

export async function aaveV2ActionDeposit (symbol, amount, enable) {
  await verifyPreconditions()

  const steps = []

  if (amount === ALL) {
    if (symbol === 'ETH') throw new Error('ALL is not supported for ETH')

    amount = await scaleToTemporaryBigNumber(symbol, await getErc20Balance(symbol))
  }

  const contractAddress = symbol === 'ETH' ? aave.API_ETH_MOCK_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!contractAddress) throw new Error(`No contract address found for ${symbol}`)

  if (Number(amount) > 0) {
    const getTxs = async () => await getAaveLendingPoolForAsset(symbol).deposit({
      user: get(connectedWalletAddress),
      reserve: contractAddress,
      amount,
      referralCode: aaveReferralCode
    })

    const txs = await getTxs()
    console.log('Aave deposit TX result array:', txs)

    if (txs.some(tx => tx.txType === 'ERC20_APPROVAL')) {
      steps.push(getAaveV2ApproveStep(symbol, getTxs))
    }

    steps.push({
      id: 'deposit',
      description: `Deposit ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} to liquidity pool`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        const txs = await getTxs()
        const txData = await txs.find(tx => tx.txType === 'DLP_ACTION')?.tx()
        if (!txData) return null

        const hash = await waitForTxHashOnly(sendTransaction(txData), setWaitingForUser)

        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV2',
            type: 'deposit',
            symbol,
            amount: Number(amount)
          },
          icon: 'sign-in-alt',
          description: `Deposit ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} to Aave V2`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })
  }

  if (enable != null) {
    steps.push(...await aaveV2ActionEnableDisable(symbol, enable))
  }

  return steps
}

export async function aaveV2ActionWithdraw (symbol, amount) {
  await verifyPreconditions()

  const steps = []

  let actualAmount = amount
  if (actualAmount === ALL) {
    const actualSymbol = symbol === 'ETH' ? 'WETH' : symbol
    const { currentATokenBalance } = await aaveV2GetUserReserveData(actualSymbol)

    actualAmount = await scaleToTemporaryBigNumber(actualSymbol, currentATokenBalance)
  }

  if (!Number(actualAmount)) return []

  const contractAddress = symbol === 'ETH' ? aave.API_ETH_MOCK_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!contractAddress) throw new Error(`No contract address found for ${symbol}`)

  let aTokenAddress
  if (symbol === 'ETH') {
    const lendingPool = await getLendingPoolContract('WETH')
    ;({ aTokenAddress } = await lendingPool.getReserveData(get(markets).coins.WETH.tokenAddress))
    const approvalStep = await getWEthGatewayApproveStep('aWETH', aTokenAddress, amount)
    if (approvalStep) steps.push(approvalStep)
  }

  const getTxs = async () => await getAaveLendingPoolForAsset(symbol).withdraw({
    user: get(connectedWalletAddress),
    reserve: contractAddress,
    amount: amount === ALL ? '-1' : amount,
    aTokenAddress
  })

  const txs = await getTxs()
  console.log('Aave withdraw TX result array:', txs)

  steps.push({
    id: 'withdraw',
    description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const txs = await getTxs()
      const txData = await txs.find(tx => tx.txType === 'DLP_ACTION')?.tx()
      if (!txData) return null

      const hash = await waitForTxHashOnly(sendTransaction(txData), setWaitingForUser)

      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'aaveV2',
          type: 'withdraw',
          symbol,
          ...amount === ALL ? { all: true } : { amount: Number(amount) }
        },
        icon: 'sign-out-alt',
        description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from Aave V2`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function aaveV2ActionBorrow (symbol, amount, interestRateMode = 'Variable') {
  await verifyPreconditions()

  const steps = []

  if (amount === ALL) throw new Error('ALL is not supported for borrow')

  const contractAddress = symbol === 'ETH' ? aave.API_ETH_MOCK_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!contractAddress) throw new Error(`No contract address found for ${symbol}`)

  let debtTokenAddress
  if (symbol === 'ETH') {
    const lendingPool = await getLendingPoolContract('WETH')
    const { stableDebtTokenAddress, variableDebtTokenAddress } = await lendingPool.getReserveData(get(markets).coins.WETH.tokenAddress)
    debtTokenAddress = interestRateMode === 'Stable' ? stableDebtTokenAddress : variableDebtTokenAddress
    const approvalStep = await getWEthGatewayDelegationStep(interestRateMode === 'Stable' ? 'stableDebtWETH' : 'variableDebtWETH', debtTokenAddress, amount)
    if (approvalStep) steps.push(approvalStep)
  }

  const getTxs = async () => await getAaveLendingPoolForAsset(symbol).borrow({
    user: get(connectedWalletAddress),
    reserve: contractAddress,
    amount,
    interestRateMode,
    referralCode: aaveReferralCode,
    debtTokenAddress
  })

  const txs = await getTxs()
  console.log('Aave borrow TX result array:', txs)

  steps.push({
    id: 'borrow',
    description: `Borrow ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const txs = await getTxs()
      const txData = await txs.find(tx => tx.txType === 'DLP_ACTION')?.tx()
      if (!txData) return null

      const hash = await waitForTxHashOnly(sendTransaction(txData), setWaitingForUser)

      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'aaveV2',
          type: 'borrow',
          symbol,
          amount: Number(amount),
          interestRateMode
        },
        icon: 'sign-out-alt',
        description: `Borrow ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) from Aave V2`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function aaveV2ActionRepay (symbol, amount, interestRateMode = null) {
  await verifyPreconditions()

  const contractAddress = symbol === 'ETH' ? aave.API_ETH_MOCK_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  const internalContractAddress = symbol === 'ETH' ? get(markets).coins.WETH?.tokenAddress : contractAddress
  if (!contractAddress) throw new Error(`No contract address found for ${symbol}`)

  if (!interestRateMode) {
    const amountInSmallestUnit = amount === ALL ? null : await getAmountInSmallestUnit(symbol, Number(amount))
    const amountBN = amountInSmallestUnit && ethers.BigNumber.from(amountInSmallestUnit)

    const lendingPool = await getLendingPoolContract(symbol)
    const { stableDebtTokenAddress, variableDebtTokenAddress } = await lendingPool.getReserveData(internalContractAddress)

    const stableDebtBalance = await getErc20Balance(stableDebtTokenAddress)
    const variableDebtBalance = await getErc20Balance(variableDebtTokenAddress)

    if (!Number(stableDebtBalance) && !Number(variableDebtBalance)) return []

    if (stableDebtBalance.gt(0) && variableDebtBalance.gt(0)) {
      if (amount === ALL) {
        return [...await aaveV2ActionRepay(symbol, ALL, 'Stable'), ...await aaveV2ActionRepay(symbol, ALL, 'Variable')]
      } else {
        const priority = await dialogs.open(RepayPriorityPrompt, {
          symbol,
          stableAmount: await getAmountForDisplay(symbol, stableDebtBalance),
          variableAmount: await getAmountForDisplay(symbol, variableDebtBalance)
        })

        if (!priority) return

        const order = [{ type: 'Variable', balance: variableDebtBalance }, { type: 'Stable', balance: stableDebtBalance }]
        if (priority === 'Variable') order.reverse()

        const steps = []
        let toBePaid = ethers.BigNumber.from(amountBN)
        for (const repayObject of order) {
          if (toBePaid.gte(repayObject.balance)) {
            steps.push(...await aaveV2ActionRepay(symbol, ALL, repayObject.type))
            toBePaid = toBePaid.sub(repayObject.balance)
          } else {
            steps.push(...await aaveV2ActionRepay(symbol, await scaleToTemporaryBigNumber(symbol, toBePaid), repayObject.type))
            toBePaid = ethers.BigNumber.from(0)
            break
          }
        }

        if (!steps.length) throw new Error('No repay steps')
        return steps
      }
    } else if (stableDebtBalance.gt(0)) {
      return await aaveV2ActionRepay(symbol, amount, 'Stable')
    } else if (variableDebtBalance.gt(0)) {
      return await aaveV2ActionRepay(symbol, amount, 'Variable')
    } else {
      throw new Error('No debt to repay')
    }
  } else {
    const getTxs = async () => await getAaveLendingPoolForAsset(symbol).repay({
      user: get(connectedWalletAddress),
      reserve: contractAddress,
      amount: amount === ALL ? '-1' : amount,
      interestRateMode
    })

    const txs = await getTxs()
    console.log('Aave repay TX result array:', txs)

    const steps = []

    if (txs.some(tx => tx.txType === 'ERC20_APPROVAL')) {
      steps.push(getAaveV2ApproveStep(symbol, getTxs))
    }

    steps.push({
      id: 'repay',
      description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) to liquidity pool`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        const txs = await getTxs()
        const txData = await txs.find(tx => tx.txType === 'DLP_ACTION')?.tx()
        if (!txData) return null

        const hash = await waitForTxHashOnly(sendTransaction(txData), setWaitingForUser)

        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV2',
            type: 'repay',
            symbol,
            ...amount === ALL ? { all: true } : { amount: Number(amount) },
            interestRateMode
          },
          icon: 'sign-in-alt',
          description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) to Aave V2`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })

    return steps
  }
}

import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseModule from '@web3-onboard/coinbase'
import trezorModule from '@web3-onboard/trezor'
import ledgerModule from '@web3-onboard/ledger'
import walletConnectModule from '@web3-onboard/walletconnect'
import { createLoadingStore } from './loading'
import { writable, derived, get } from 'svelte/store'
import { ethers } from 'ethers'
import { toChecksumAddress } from 'ethereum-checksum-address'
import { delay } from '../lib/utils'
import { currentDevOptions } from './devOptions'

export const WEHODL_CHAIN_ID = Symbol('WEHODL_CHAIN_ID')

export const chains = [{
  id: '0x1',
  token: 'ETH',
  label: 'Ethereum Mainnet',
  rpcUrl: window.appVariables.ethNodeUrls.mainnet,
  [WEHODL_CHAIN_ID]: 'mainnet'
}]

export const chainsByWehodlChainId = Object.fromEntries(chains.map(c => [c[WEHODL_CHAIN_ID], c]))

const walletConnectOptions = {
  projectId: window.appVariables.walletConnectProjectId,
  requiredChains: [1],
  dappUrl: 'https://wehodl.finance'
}

export const onboard = Onboard({
  wallets: [
    injectedModule(),
    coinbaseModule(),
    trezorModule({
      email: 'support@wehodl.finance',
      appUrl: 'https://wehodl.finance'
    }),
    ...ledgerModule ? [ledgerModule(walletConnectOptions)] : [],
    ...walletConnectModule ? [walletConnectModule(walletConnectOptions)] : []
  ],
  chains,
  appMetadata: {
    name: 'WEHODL',
    icon: '/images/logo_square.svg',
    description: 'DeFi Borrow-Liquidation Calculator',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
      { name: 'Exodus', url: 'https://www.exodus.com/' }
    ]
  },
  accountCenter: {
    desktop: {
      enabled: currentDevOptions.debugOptions.showOnboardAccountCenter // This is for testing only
    },
    mobile: {
      enabled: currentDevOptions.debugOptions.showOnboardAccountCenter // This is for testing only
    }
  },
  notify: {
    desktop: {
      position: 'bottomRight'
    },
    mobile: {
      position: 'bottomRight'
    }
  }
})

// Fix display issues on mobile
const onboardElement = document.querySelector('onboard-v2')
const customCss = new window.CSSStyleSheet()
customCss.replaceSync(`
  .mobile-header .icon-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .wallets-container {
    flex-wrap: wrap;
  }
`)
onboardElement.shadowRoot.adoptedStyleSheets.push(customCss)

function getWalletLabelFromEnvironment () {
  if (window.web3?.currentProvider?.isCoinbaseBrowser) {
    return 'Coinbase Wallet'
  } else if (navigator.userAgent.includes('MetaMaskMobile')) {
    return 'MetaMask'
  }
  return null
}

export const environmentWallet = getWalletLabelFromEnvironment()

let previousWallets
try {
  previousWallets = JSON.parse(window.localStorage.wehodlBlocknativeConnectedWallets)
} catch {}

export const walletConnecting = createLoadingStore()
export const walletRestoring = createLoadingStore()

export async function connectWallet (options) {
  if (options === true) {
    const wallet = await walletConnecting(async () => {
      return await restoreWalletConnection(false, true)
    })
    if (wallet) {
      return wallet
    } else {
      options = undefined
    }
  }

  if (environmentWallet && !options?.autoSelect) {
    options = {
      ...options ?? {},
      autoSelect: {
        label: environmentWallet, disableModals: true
      }
    }
  }

  return await walletConnecting(async () => {
    return await onboard.connectWallet(options)
  })
}

export async function disconnectAllWallets () {
  return await walletConnecting(async () => {
    for (const wallet of onboard.state.get().wallets) {
      await onboard.disconnectWallet({ label: wallet.label })
    }
  })
}

export const walletInitialized = writable(false)

export async function restoreWalletConnection (rereadPreviousWallets = false, useLastFromThisSession = false) {
  if (rereadPreviousWallets) {
    try {
      previousWallets = JSON.parse(window.localStorage.wehodlBlocknativeConnectedWallets)
    } catch {}
  }

  if (useLastFromThisSession) {
    previousWallets = lastConnectedWalletInThisSession ? [lastConnectedWalletInThisSession] : []
  }

  let wallet
  if (previousWallets?.length) {
    await walletRestoring(async () => {
      try {
        wallet = await connectWallet({
          autoSelect: { label: previousWallets[0], disableModals: true }
        })
      } catch (e) {
        console.error('Failed to reconnect wallet', previousWallets[0], e)
      }
    })
  }

  previousWallets = undefined

  walletInitialized.set(true)
  return wallet
}

function getChainObject (chainId) {
  return chains.find(({ id }) => id === chainId) ?? {
    id: chainId,
    token: 'ETH',
    label: `Unknown network (${chainId})`,
    [WEHODL_CHAIN_ID]: chainId
  }
}

const prevChainId = window.localStorage.wehodlBlocknativeChainId ?? chains[0].id
export let currentChainVal = getChainObject(prevChainId)
export const currentChain = writable(currentChainVal)

export const onboardState = writable(onboard.state.get())
onboard.state.select().subscribe(state => {
  onboardState.set(state)
})

let lastConnectedWalletInThisSession = null

onboardState.subscribe(({ wallets }) => {
  if (previousWallets?.length && !wallets.length) return
  window.localStorage.wehodlBlocknativeConnectedWallets = JSON.stringify(wallets.map(({ label }) => label))

  if (wallets.length) lastConnectedWalletInThisSession = wallets[0].label

  const chainId = wallets[0]?.chains[0]?.id
  if (chainId && currentChainVal.id !== chainId) {
    currentChainVal = getChainObject(chainId)
    window.localStorage.wehodlBlocknativeChainId = chainId
    currentChain.set(currentChainVal)
  }
})

export const activeWallet = derived(onboardState, $onboardState => $onboardState.wallets[0])
export const activeWalletAddress = derived(activeWallet, $activeWallet => {
  const address = $activeWallet?.accounts[0]?.address
  if (!address) return null
  return toChecksumAddress(address)
})

export function getProvider (throwIfNoWallet = true) {
  if (throwIfNoWallet) {
    const provider = getProvider(false)
    if (!provider) throw new Error('No valid wallet connected')
    return provider
  } else {
    const { wallets } = onboard.state.get()
    if (!wallets.length) return undefined
    return new ethers.providers.Web3Provider(wallets[0].provider, 'any')
  }
}

export const currentProvider = derived(activeWallet, $activeWallet => getProvider(false))

export function expandAccountCenter () {
  setTimeout(() => onboard.state.actions.updateAccountCenter({ expanded: true }), 0)
}

export async function ensureNetwork (network) {
  const onboardChain = chains.find(c => c[WEHODL_CHAIN_ID] === network)
  if (!onboardChain) throw new Error(`Unknown network "${network}"`)
  if (currentChainVal[WEHODL_CHAIN_ID] !== network) {
    await onboard.setChain({ chainId: onboardChain.id })
    await delay(1000)
  }
  if (currentChainVal[WEHODL_CHAIN_ID] !== network) {
    throw new Error(`Failed to switch to required network! To continue, please switch your wallet to ${onboardChain.label} manually.`)
  }
}

export async function sendTx (txDetails, withPreflightNotifications = false) {
  const ethersProvider = getProvider(true)
  const signer = ethersProvider.getSigner()

  const wallet = get(activeWallet)
  if (!wallet) throw new Error('No wallet connected')

  const sendTransaction = async () => {
    console.log('Sending TX', txDetails)
    const tx = await signer.sendTransaction(txDetails)
    console.log('TX result', tx)
    return tx.hash
  }

  let transactionHash
  if (withPreflightNotifications) {
    const { balance } = wallet.accounts[0]
    const balanceValue = Object.values(balance)[0]

    const gasPrice = async () => {
      const val = await ethersProvider.getGasPrice()
      return val.toString()
    }

    const estimateGas = async () => {
      const gas = await ethersProvider.estimateGas(txDetails)
      return gas.toString()
    }

    transactionHash = await onboard.state.actions.preflightNotifications({
      sendTransaction,
      gasPrice,
      estimateGas,
      balance: balanceValue,
      txDetails
    })
  } else {
    transactionHash = await sendTransaction()
  }

  console.log('TX hash', transactionHash)

  return transactionHash
}

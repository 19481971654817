<script>
  export let title = null
  export let closeable = false
  export let large = false
  export let overflow = false
  export let fieldBg = false

  import { createEventDispatcher } from 'svelte'
  import { scale } from 'svelte/transition'

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }

  // Appending to body to avoid other CSS rules accidentally being applied to the modal
  function appendToBody (element) {
    dispatch('mount')

    // In case there is a wrapper by Svelte for CSS variables
    if (element.parentNode?.getAttribute('style')?.startsWith('display: contents; --')) element = element.parentNode

    document.body.appendChild(element)
  }
</script>

<style lang="scss">
  @import 'bulma/sass/utilities/all';

  .dialog {
    .modal-card {
      width: var(--width, auto);
      max-width: calc(100vw - 16px);
      max-height: calc(min(100vh - 40px, var(--max-height, 100vh)));
      
      &.fieldBg .modal-card-body {
        background-color: $field-bg;

        :global(hr) {
          background-color: $field-bg-hr;
        }
      }

      // Fade to white vertically
      &:not(.fieldBg) {
        background-color: $modal-card-body-background-color;
        .modal-card-body {
          // "Black" is opaque here
          mask-image: linear-gradient(to bottom, transparent 0%, black 15px, black calc(100% - 15px), transparent 100%);
        }
      }

      &.overflow {
        overflow: visible;

        .modal-card-body {
          overflow: visible;
        }
      }

      :global(.left-buttons) {
        flex: 1;

        :global(.no-min-width) {
          min-width: auto !important;
        }
      }

      &.is-large {
        min-width: var(--min-width, 75vw);
        max-width: var(--max-width, calc(100vw - 16px));
      }

      @media (max-width: 768px) {
        max-height: calc(100vh - 16px);
        margin: 8px;

        &.is-large {
          min-width: calc(100vw - 16px);
        }
      }

      .modal-card-head {
        font-size: $size-5;
        font-weight: $weight-semibold;

        .modal-card-title {
          flex: 1;
          word-break: break-word;
        }
      }

      .modal-card-body {
        &.is-titleless {
          border-top-left-radius: $radius-large;
          border-top-right-radius: $radius-large;
        }

        &.is-footerless {
          border-bottom-left-radius: $radius-large;
          border-bottom-right-radius: $radius-large;
        }
      }

      .modal-card-foot {
        justify-content: flex-end;

        :global(.button) {
          display: inline; // Fix Safari centering
          min-width: 5em;
          font-weight: $weight-semibold;
        }
      }

      @include tablet {
        min-width: var(--min-width, 320px);
      }
    }
  }
</style>

<div class="modal dialog custom is-active {$$props.class ?? ''}" use:appendToBody>
  <div class="modal-background" on:click={close}></div>
  <div class="modal-card" class:is-large={large} class:overflow class:fieldBg transition:scale={{ start: 1.2 }} on:introend>
    {#if title || closeable || $$slots.title}
      <header class="modal-card-head">
        <div class="modal-card-title">{title ?? ''}<slot name="title" /></div>
        {#if closeable}<button class="delete is-medium" aria-label="close" on:click={close} title="Close"></button>{/if}
      </header>
    {/if}

    <section class="modal-card-body fade-to-white" class:is-titleless={!title && !closeable} class:is-footerless={!$$slots.footer}>
      <main><slot /></main>
    </section>

    {#if $$slots.footer}
      <footer class="modal-card-foot">
        <slot name="footer" />
      </footer>
    {/if}
  </div>
</div>

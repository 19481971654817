<script>
  import { fly } from 'svelte/transition'

  export let value = ''
  export let danger = false
</script>

<style lang="scss">
  main {
    font-size: var(--font-size, 250%);
    font-weight: bold;
    color: var(--color, inherit);

    display: inline-grid;
    grid-template-columns: minmax(max-content, 100%);
    justify-content: center;

    &.danger {
      color: var(--danger-color, rgb(232, 59, 42));
    }
  }

  .wrapper {
    grid-column: 1;
    grid-row: 1;
  }

  .outer {
    display: inline-grid;
    grid-template-columns: min-content;
  }

  .inner {
    grid-column: 1;
    grid-row: 1;
    white-space: pre;
  }
</style>

<main class:danger>
  {#key value.replace(/\d/g, '*')}
    <span class="wrapper" in:fly|local={{ y: 20, delay: 60 }} out:fly|local={{ y: -20, delay: 0 }}>
      {#each Object.entries(value) as [i, c] (i)}
        <span class="outer" in:fly|local={{ y: 20, delay: 30 * i + 60 }} out:fly|local={{ y: -20, delay: 30 * i }}>
          {#key c}
            <span class="inner" in:fly|local={{ y: 20, delay: 30 * i + 60 }} out:fly|local={{ y: -20, delay: 30 * i }}>{c}</span>
          {/key}
        </span>
      {/each}
    </span>
  {/key}
</main>

<script>
  import './styles/app.scss'

  import html from 'html-template-tag'
  import { GoogleAnalytics } from '@beyonk/svelte-google-analytics'
  import { onDestroy } from 'svelte'
  import Nav from './components/Nav.svelte'
  import { RouterView, router, shouldFixRouter } from './router'
  import { Toast } from 'svelma-fixed'
  import ThorchainBtcDepositUi from './components/ThorchainBtcDepositUi.svelte'
  import ThorchainStuckWalletBalanceUi from './components/ThorchainStuckWalletBalanceUi.svelte'
  import { appInfo, initAppInfo } from './lib/appInfo'
  import markets from './stores/markets'
  import lastKnownVersionId from './stores/version'
  import { initTxManager } from './stores/txManager'
  import { initThorchainTxManager } from './stores/thorchainTxManager'
  import { initThorchainBtcDepositUpdate } from './stores/thorchainBtcDeposit'
  import { formatError } from './lib/utils'
  import dialogs from './stores/dialogs'
  import globalComponents from './lib/globalComponents'

  $lastKnownVersionId = window.appVariables.versionId // eslint-disable-line prefer-const
  $: if ($lastKnownVersionId !== window.appVariables.versionId) {
    console.log('Detected new version from another tab:', window.appVariables.versionId)
    location.reload()
  }

  // Workaround for https://github.com/spaceavocado/svelte-router/issues/13
  let navCount = 0
  let unregister = $router.onNavigationChanged((from, to) => {
    if (shouldFixRouter) {
      navCount++
      window.scrollTo(0, 0)
    }
  })

  onDestroy(() => {
    unregister()
    unregister = null
  })

  const appInfoPromise = (async () => {
    try {
      await Promise.all([
        (async () => {
          if (!appInfo.initialized) await initAppInfo()
        })(),
        (async () => {
          if (!markets.initialized) {
            markets.initAutoUpdate()
            try {
              await markets.update()
            } catch (e) {
              console.error('Initial markets update failed', e)
            }
          }
        })()
      ])

      initTxManager()
      initThorchainTxManager()
      initThorchainBtcDepositUpdate()

      window.onerror = (message, file, row, col, error) => {
        if (message === 'ResizeObserver loop limit exceeded') return // Not sure where this error comes from
        Toast.create({ message: html`An error occured: ${formatError(error) ?? message}`, type: 'is-danger' })
      }

      window.onunhandledrejection = ({ reason }) => {
        if (reason?.stack?.includes('toLowerCase') && reason.stack.includes('updateBalances')) return // Web3-Onboard bug
        if (reason?.stack?.includes('contentScriptMain.bundle.js')) return // Svelte DevTools bug
        if (reason?.message === 'Util.getNetNameWithChainId invalid chainId.') return // Compound.js issue with unsupported chain (irrelevant due to ensureNetwork)
        Toast.create({ message: html`An error occured: ${formatError(reason)}`, type: 'is-danger' })
      }

      document.body.classList.add('app-ready')
    } catch (e) {
      setTimeout(() => { throw e }, 0) // We want the global onerror to handle this one too
      throw e
    }
  })()
</script>

{#if window.appVariables.gaMeasurementId}
  <GoogleAnalytics
    properties={[window.appVariables.gaMeasurementId]}
    configurations={{ [window.appVariables.gaMeasurementId]: {} }}
  />
{/if}

{#await appInfoPromise}
  <!-- Loading -->
{:then}
  <Nav bind:this={globalComponents.nav} />

  <div id="sticky-footer-outer-wrapper">
    <div id="sticky-footer-inner-wrapper">
      <ThorchainStuckWalletBalanceUi />

      {#key navCount}
        <RouterView />
      {/key}
    </div>
  </div>
{:catch}
  <!-- onerror will show the error here -->
{/await}

<ThorchainBtcDepositUi />

{#each $dialogs as dialog (dialog.id)}
  <svelte:component this={dialog.Component} {...dialog.props} on:close={e => dialogs.close(dialog.id, e.detail)} />
{/each}

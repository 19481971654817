import { get, derived } from 'svelte/store'
import { persistentWritable } from './persistentStore'
import { propertyStore } from 'svelte-writable-derived'
import { getAddressShortLabel, confirmWithDoNotShowAgain } from '../lib/utils'
import * as onboard from './onboard'
import { apiCall } from '../lib/api'
import { calculateFullState } from '../lib/liquidationCalculator'
import services from '../lib/services'
import { createLoadingStore } from './loading'
import { toChecksumAddress } from 'ethereum-checksum-address'
import { Dialog, Toast } from 'svelma-fixed'
import ExtendableError from 'es6-error'
import { ga } from '@beyonk/svelte-google-analytics'
import { tick } from 'svelte'
import html from 'html-template-tag'
import { calculatorState, currentCoreData, switchService } from '../stores/calculatorState'
import { quoteCoin } from './quoteCoin'
import markets from './markets'

export { getAddressShortLabel, toChecksumAddress }

function getEmptyWalletManagerObject () {
  return { registeredWallets: {}, connectedWalletAddress: null, importedWalletAddress: null, mode: 'readOnly' }
}

function handleWalletTypeIcon (iconData) {
  if (iconData.startsWith('data:')) {
    const svgImage = document.createElementNS('http://www.w3.org/2000/svg', 'image')
    svgImage.setAttribute('width', '100%')
    svgImage.setAttribute('height', '100%')
    svgImage.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', iconData)

    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    svgElement.setAttribute('width', '100%')
    svgElement.setAttribute('height', '100%')
    svgElement.appendChild(svgImage)

    return svgElement.outerHTML
  }
  return iconData
}

function findServiceWithMaxValue (walletData) {
  const services = Object.entries(walletData.services)
    .map(([service, serviceData]) => calculateFullState(
      { assetPrices: {}, quoteCoinPrices: {} },
      get(quoteCoin),
      get(markets),
      { service, assets: { collateral: [...serviceData.collateralAssets, ...serviceData.unusedSupplyAssets], borrow: serviceData.borrowAssets } }
    ))

  for (const balanceType of ['borrow', 'collateral', 'supply']) {
    const top = services
      .map(({ service, calcValues }) => ({ service, value: calcValues[`${balanceType}Value`] }))
      .filter(({ value }) => value)
      .sort((a, b) => b.value - a.value)[0]?.service
    if (top) return { id: top, balance: balanceType }
  }

  return null
}

function switchServiceIfNoFunds (walletData) {
  const currentService = walletData.services[get(currentCoreData).service]
  if (currentService.collateralAssets.length || currentService.borrowAssets.length || currentService.unusedSupplyAssets.length) return

  const service = findServiceWithMaxValue(walletData)
  if (service) {
    switchService(service.id)
    Toast.create({ message: html`Switched to ${services[service.id].name} as it has the highest ${service.balance} balance`, type: 'is-info' })
  }
}

const CURRENT_WALLET_MANAGER_VERSION = 4

if (!window.localStorage.wehodlWalletManager) window.localStorage.wehodlWalletManagerVersion = CURRENT_WALLET_MANAGER_VERSION
export const walletManager = persistentWritable('wehodlWalletManager', getEmptyWalletManagerObject())

if (isNaN(window.localStorage.wehodlWalletManagerVersion)) window.localStorage.wehodlWalletManagerVersion = 1

if (window.localStorage.wehodlWalletManagerVersion < 2) {
  walletManager.update($walletManager => {
    if ($walletManager.mode === 'metaMask') $walletManager.mode = 'readOnly'
    for (const wallet of Object.values($walletManager.registeredWallets)) {
      if (wallet.mode === 'metaMask') wallet.mode = 'readOnly'
    }
    return $walletManager
  })

  window.localStorage.wehodlWalletManagerVersion = 2
}

if (window.localStorage.wehodlWalletManagerVersion < 3) {
  walletManager.update($walletManager => {
    $walletManager.connectedWalletAddress = $walletManager.mode === 'readOnly' ? $walletManager.activeWalletAddress : null
    $walletManager.importedWalletAddress = $walletManager.mode === 'blocknative' ? $walletManager.activeWalletAddress : null

    for (const wallet of Object.values($walletManager.registeredWallets)) {
      delete wallet.mode
    }

    return $walletManager
  })

  window.localStorage.wehodlWalletManagerVersion = 3
}

if (window.localStorage.wehodlWalletManagerVersion < 4) {
  walletManager.update($walletManager => {
    for (const wallet of Object.values($walletManager.registeredWallets)) {
      if (!wallet.thorchainTransactions) wallet.thorchainTransactions = []
    }

    return $walletManager
  })

  window.localStorage.wehodlWalletManagerVersion = 4
}

export default walletManager

export const registeredWallets = propertyStore(walletManager, 'registeredWallets')
export const mode = propertyStore(walletManager, 'mode')
export const activeWalletAddress = propertyStore(walletManager, 'activeWalletAddress')
export const connectedWalletAddress = propertyStore(walletManager, 'connectedWalletAddress')
export const importedWalletAddress = propertyStore(walletManager, 'importedWalletAddress')

export const updatingLiveData = createLoadingStore()
let lastUpdatingAddress = null

export const activeWallet = derived(
  [registeredWallets, activeWalletAddress],
  ([$registeredWallets, $activeWalletAddress]) => $registeredWallets[$activeWalletAddress]
)

export const connectedWallet = derived(
  [registeredWallets, connectedWalletAddress],
  ([$registeredWallets, $connectedWalletAddress]) => $registeredWallets[$connectedWalletAddress]
)

export const importedWallet = derived(
  [registeredWallets, importedWalletAddress],
  ([$registeredWallets, $importedWalletAddress]) => $registeredWallets[$importedWalletAddress]
)

export function createWalletObject (options) {
  if (!options.address) throw new Error('Wallet address is required')
  return {
    history: [],
    thorchainTransactions: [],
    liveData: null,
    lastSelected: Date.now(),
    ...options
  }
}

export function normalizeWallet (address) {
  if (typeof address !== 'string') return address
  return toChecksumAddress(address)
}

export function setWalletDirect (walletMode, setMode = false, address, options = {}) {
  address = normalizeWallet(address)

  const $walletManager = get(walletManager)
  if (address === undefined) {
    throw new Error('Wallet address cannot be undefined')
  } else if (address !== null && !$walletManager.registeredWallets[address]) {
    $walletManager.registeredWallets[address] = createWalletObject({ address, ...options })
  }

  if (address) {
    delete $walletManager.registeredWallets[address].mode
    Object.assign($walletManager.registeredWallets[address], options)
  }

  if (walletMode === 'blocknative') {
    $walletManager.connectedWalletAddress = address
  } else {
    $walletManager.importedWalletAddress = address
  }

  if (setMode) {
    if (!address) {
      $walletManager.mode = 'readOnly' // Using this with connected wallet mode but without address means disconnecting, which requires switching to calculator
      $walletManager.activeWalletAddress = $walletManager.importedWalletAddress
    } else {
      $walletManager.mode = walletMode
    }
  }

  if ($walletManager.mode === walletMode) {
    $walletManager.activeWalletAddress = address
  }

  if (address) $walletManager.registeredWallets[address].lastSelected = Date.now()

  walletManager.set($walletManager)
}

export function setImportedWallet (address, options = {}) {
  setWalletDirect('readOnly', true, address, options)

  if (address) {
    ga.all.search(address)
  } else {
    ga.addEvent('wallet_clear_import', {})
  }
}

export function unlinkConnectedWallet () {
  setConnectedWallet(null)
}

export function unlinkImportedWallet () {
  setImportedWallet(null)
}

export function setConnectedWallet (address, options = {}, setMode = true) {
  const prevConnectedWalletAddress = get(connectedWalletAddress)

  setWalletDirect('blocknative', setMode, address, options)

  if (address) {
    if (address.toLowerCase() !== prevConnectedWalletAddress?.toLowerCase()) ga.addEvent('wallet_connect', { type: 'blocknative', address: 'addr:' + address, walletTypeLabel: options.walletTypeLabel ?? 'Wallet' })
  } else {
    if (prevConnectedWalletAddress) ga.addEvent('wallet_disconnect', {})

    // Asynchronous, we don't have to wait for this to finish
    tick().then(() => onboard.disconnectAllWallets().catch(e => console.warn('Failed to disconnect all wallets', e)))
  }
}

export function setConnectedWalletBg (address, options = {}) {
  setConnectedWallet(address, options, false)
}

function removeRaw (obj, set = new Set()) {
  if (set.has(obj)) return
  set.add(obj)

  delete obj.raw

  for (const value of Object.values(obj)) {
    if (value && typeof value === 'object') {
      removeRaw(value, set)
    }
  }
}

export function switchMode (mode) {
  walletManager.update($walletManager => {
    $walletManager.mode = mode

    if (mode === 'blocknative') {
      $walletManager.activeWalletAddress = $walletManager.connectedWalletAddress
    } else {
      $walletManager.activeWalletAddress = $walletManager.importedWalletAddress
    }

    return $walletManager
  })
}

export async function loadWalletLiveData (address) {
  console.log('Loading live data for wallet', address)
  return await updatingLiveData(async () => {
    const data = { ...await apiCall('POST', '/api/loadAddress', { address }), lastUpdate: Date.now() }
    removeRaw(data)
    console.log('Live data ready', data)
    return data
  })
}

export class SymbolClashError extends ExtendableError {
  constructor () {
    super('There are several assets with the same symbol in use. This is currently not supported by WEHODL for connecting wallets! (You can still load the wallet in read-only mode.)')
  }
}

export async function updateLiveData (withConfirmation = false) {
  const address = get(activeWalletAddress)

  if (address && withConfirmation && Object.values(get(calculatorState).services).some(s => s.dirty)) {
    if (!await confirmWithDoNotShowAgain('reloadImportedWallet', {
      title: 'Reload imported wallet',
      message: html`<div style="font-size: 200%; text-align: center; margin-top: -0.5em; margin-bottom: 0.5em;"><i class="fas fa-sync"></i></div>This action will reload the imported wallet values and clear any values you added to the calculator.`,
      type: 'is-primary',
      confirmText: 'Continue'
    })) return
  }

  lastUpdatingAddress = address
  if (address) {
    const liveData = await loadWalletLiveData(address)

    if (liveData.symbolClash && get(connectedWalletAddress) === address) {
      unlinkConnectedWallet()
      throw new SymbolClashError()
    }

    try {
      setWalletLiveData(address, liveData)
    } catch (e) {
      console.warn(`Failed to set live data for ${address}`, e)
    }
  }
}

export async function searchWallet (address, confirmIfNoData = false) {
  const liveData = await loadWalletLiveData(address)

  if (confirmIfNoData && !Object.values(liveData.services).some(s => s.collateralAssets.length)) {
    if (!await Dialog.confirm({
      title: 'No loan was found',
      message: 'No loan was found for the wallet address you submitted on the supported DeFi protocols. Clicking Continue will load the wallet\'s basic information, but all loan calculation values will display as zero.',
      type: 'is-primary',
      confirmText: 'Confirm'
    })) return
  }

  setImportedWallet(address, { liveData })
  switchServiceIfNoFunds(liveData)
}

export async function connectBlocknative (restoreFromCurrentSession = false, background = false) {
  await onboard.disconnectAllWallets()
  const [wallet] = await onboard.connectWallet(restoreFromCurrentSession)
  if (!wallet) return // Nothing to do here

  const address = get(onboard.activeWalletAddress)

  const liveData = await loadWalletLiveData(address)

  if (liveData.symbolClash) {
    tick().then(() => onboard.disconnectAllWallets())
    throw new SymbolClashError()
  }

  setConnectedWallet(address, { liveData, walletTypeLabel: wallet.label, walletTypeIconHtml: handleWalletTypeIcon(wallet.icon) }, !background)
  switchServiceIfNoFunds(liveData)
}

export function removeWallet (address) {
  const $walletManager = get(walletManager)
  if ($walletManager.connectedWalletAddress === address) {
    unlinkConnectedWallet()
  }
  if ($walletManager.importedWalletAddress === address) {
    unlinkImportedWallet()
  }
  delete $walletManager.registeredWallets[address]
  walletManager.set($walletManager)
}

export function clear () {
  tick().then(() => onboard.disconnectAllWallets())
  walletManager.set(getEmptyWalletManagerObject())
}

export function setWalletLiveData (address, liveData) {
  walletManager.update($walletManager => {
    if (!$walletManager.registeredWallets[address]) throw new Error(`Trying to set liveData for wallet ${address}, but it's not registered`)
    $walletManager.registeredWallets[address].liveData = liveData
    return $walletManager
  })
}

export async function updateLiveDataIfNeeded ($activeWallet = get(activeWallet)) {
  if (($activeWallet?.liveData?.lastUpdate ?? 0) < Date.now() - window.appVariables.liveDataUpdateInterval && (!get(updatingLiveData) || lastUpdatingAddress !== $activeWallet?.address)) {
    await updateLiveData()
  }
}

const onActiveWalletChange = wallet => {
  if (get(onboard.walletInitialized)) {
    if (wallet) {
      setConnectedWalletBg(get(onboard.activeWalletAddress), { walletTypeLabel: wallet?.label, walletTypeIconHtml: handleWalletTypeIcon(wallet?.icon) })
    } else {
      unlinkConnectedWallet()
    }
  }
}

onboard.activeWallet.subscribe(onActiveWalletChange)
// This happens after wallet restoring
onboard.walletInitialized.subscribe(() => {
  onActiveWalletChange(get(onboard.activeWallet))
})

let reloadPromptShown = false
let discrepancyFixInProgress = false

connectedWallet.subscribe(async $connectedWallet => {
  if (!$connectedWallet) return

  if (!$connectedWallet?.liveData) updateLiveDataIfNeeded($connectedWallet)

  const hasDiscrepancy = () => {
    if (get(onboard.walletInitialized)) {
      const onboardWallet = get(onboard.activeWallet)
      if (onboardWallet?.accounts[0]?.address?.toLowerCase() !== $connectedWallet?.address.toLowerCase() || onboardWallet?.label !== $connectedWallet?.walletTypeLabel) {
        return true
      }
    }
    return false
  }

  if (discrepancyFixInProgress || reloadPromptShown) return

  // In case the active wallet changed from another tab and is no longer in sync with the onboard state here
  if (hasDiscrepancy()) {
    try {
      discrepancyFixInProgress = true

      await onboard.restoreWalletConnection(true)

      if (hasDiscrepancy()) {
        if (!get(onboard.activeWallet)) {
          unlinkConnectedWallet()
          return
        }

        reloadPromptShown = true
        Dialog.alert({
          message: 'The type of your wallet connection has changed from another tab. Please reload this page to continue.',
          type: 'is-warning',
          icon: 'exclamation-triangle',
          confirmText: 'Reload'
        }).then(() => {
          window.location.reload()
        })
      }
    } finally {
      discrepancyFixInProgress = false
    }
  }
})

setInterval(() => {
  if (get(mode) !== 'readOnly') updateLiveDataIfNeeded().catch(e => console.warn('Failed to update live data', e))
}, 15000)

export function getWalletName (wallet) {
  if (!wallet) return null
  return wallet.name || getAddressShortLabel(wallet.address)
}

export async function renameAddress (address) {
  const newName = await Dialog.prompt({
    message: 'Enter a new name for this wallet',
    type: 'is-primary',
    icon: 'edit',
    prompt: get(registeredWallets)[address].name ?? '',
    inputProps: { required: undefined, placeholder: getAddressShortLabel(address) },
    showCancel: true,
    confirmText: 'Save'
  })
  if (newName === null) return
  registeredWallets.update(w => {
    w[address].name = newName || undefined
    return w
  })
}

export const currentProvider = derived([connectedWallet, onboard.currentProvider], ([$connectedWallet, $onboardCurrentProvider]) => {
  if ($connectedWallet) {
    return $onboardCurrentProvider
  } else {
    return undefined
  }
})

export function getProvider (throwIfNoWallet = true) {
  const provider = get(currentProvider)
  if (!provider && throwIfNoWallet) throw new Error('No valid wallet connected')
  return provider
}

export async function ensureNetwork (network) {
  if (get(mode) === 'blocknative') {
    await onboard.ensureNetwork(network)
  } else {
    // For any future integrations not using Onboard directly...
    const onboardChain = onboard.chains.find(c => c[onboard.WEHODL_CHAIN_ID] === network)
    if (!onboardChain) throw new Error(`Unknown network "${network}"`)

    const provider = getProvider(true)
    const currentNetwork = await provider.getNetwork()
    if (currentNetwork.chainId !== Number(onboardChain.id)) throw new Error(`The selected network in your Ethereum provider must be "${network}" to execute transactions with WEHODL! Please switch your Ethereum network.`)
  }
}

export const walletConnecting = onboard.walletConnecting
export const walletRestoring = onboard.walletRestoring
export const walletAutoConnecting = createLoadingStore()

setTimeout(() => {
  if (onboard.environmentWallet && get(mode) !== 'blocknative') {
    walletAutoConnecting(() => connectBlocknative())
  } else {
    onboard.restoreWalletConnection()
  }
}, 250)

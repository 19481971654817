import { ALL, HALF_MAX_UINT256, MAX_UINT256, approve, getAllowance, getAmountForDisplay, getAmountInSmallestUnit, getApproveStep, getErc20Balance, verifyMainnetChain, waitForTxHashOnly } from '.'
import { connectedWalletAddress, currentProvider, toChecksumAddress } from '../../stores/walletManager'
import { getTxStatusByHash, upsertTransaction } from '../../stores/txManager'
import { get } from 'svelte/store'
import dialogs from '../../stores/dialogs'
import RepayPriorityPrompt from '../../components/RepayPriorityPrompt.svelte'
import markets from '../../stores/markets'
import * as ethers from 'ethers'
import { formatCurrency } from '../utils'

import aaveV3PoolAddressProviderAbi from '../abi/aaveV3PoolAddressProviderAbi.json'
import aaveV3PoolAbi from '../abi/aaveV3PoolAbi.json'
import aaveV3ProtocolDataProviderAbi from '../abi/aaveV3ProtocolDataProviderAbi.json'
import aaveV3WrappedTokenGatewayAbi from '../abi/aaveV3WrappedTokenGatewayAbi.json'
import aaveV3WethVariableDebtAbi from '../abi/aaveV3WethVariableDebtAbi.json'

export const AAVE_V3_POOL_ADDRESS_PROVIDER = '0x2f39d218133afab8f2b819b1066c7e434ad94e9e'
export const AAVE_V3_PROTOCOL_DATA_PROVIDER = '0x41393e5e337606dc3821075af65aee84d7688cbd'
export const AAVE_V3_WRAPPED_TOKEN_GATEWAY = '0x893411580e590d62ddbca8a703d61cc4a8c7b2b9'
export const AAVE_V3_WETH_VARIABLE_DEBT = '0xea51d7853eefb32b6ee06b1c12e6dcca88be0ffe'

const WETH_TOKEN_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
const AWETH_TOKEN_ADDRESS = '0x4d5f47fa6a74757f35c14fd3a6ef8e3c9bc514e8'

const REPAY_ETH_COEFFICIENT = ethers.BigNumber.from('5') // 0.005 * 10^3. Found by matching as close as possible to AAVE dApp's value
const REPAY_ETH_SCALE_FACTOR = ethers.BigNumber.from('1000') // 10^3

let provider

const aaveReferralCode = Number(window.appVariables.aaveV3ReferralCode) || 0

currentProvider.subscribe($currentProvider => {
  provider = $currentProvider
})

async function verifyPreconditions () {
  await verifyMainnetChain()
  if (!provider) throw new Error('Ethereum provider is not available')
}

async function getAddressProviderContract () {
  if (!provider) throw new Error('Ethereum provider is not available')

  const contract = await new ethers.Contract(AAVE_V3_POOL_ADDRESS_PROVIDER, aaveV3PoolAddressProviderAbi, provider.getSigner())
  return contract
}

async function getPoolContract () {
  if (!provider) throw new Error('Ethereum provider is not available')

  const addressProvider = await getAddressProviderContract()
  const poolAddress = await addressProvider.callStatic.getPool()
  const contract = await new ethers.Contract(poolAddress, aaveV3PoolAbi, provider.getSigner())
  return contract
}

async function getProtocolDataProviderContract () {
  const contract = await new ethers.Contract(AAVE_V3_PROTOCOL_DATA_PROVIDER, aaveV3ProtocolDataProviderAbi, provider.getSigner())
  return contract
}

function getWrappedTokenGatewayContract () {
  return new ethers.Contract(AAVE_V3_WRAPPED_TOKEN_GATEWAY, aaveV3WrappedTokenGatewayAbi, provider.getSigner())
}

function getWethVariableDebtContract () {
  return new ethers.Contract(AAVE_V3_WETH_VARIABLE_DEBT, aaveV3WethVariableDebtAbi, provider.getSigner())
}

export async function aaveV3GetUserReserveData (symbol) {
  await verifyPreconditions()

  const protocolDataProvider = await getProtocolDataProviderContract()

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  return await protocolDataProvider.callStatic.getUserReserveData(tokenAddress, get(connectedWalletAddress))
}

export async function aaveV3IsCollateralEnabled (symbol) {
  const { usageAsCollateralEnabled } = await aaveV3GetUserReserveData(symbol)

  return Number(usageAsCollateralEnabled) === 1
}

export async function aaveV3ActionEnableDisable (symbol, enabled) {
  await verifyPreconditions()

  const contract = await getPoolContract()

  const steps = []

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  steps.push({
    id: enabled ? 'enable' : 'disable',
    description: 'Update collateral usage state',
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      if (await aaveV3IsCollateralEnabled(symbol) === enabled) {
        return null
      } else {
        const hash = await waitForTxHashOnly(contract.setUserUseReserveAsCollateral(tokenAddress, enabled), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV3',
            type: enabled ? 'enable' : 'disable',
            symbol
          },
          icon: 'power-off',
          description: `${enabled ? 'Enable' : 'Disable'} ${symbol} for use in Aave V3`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    }
  })

  return steps
}

export async function aaveV3ActionDeposit (symbol, amount, enable) {
  await verifyPreconditions()

  const contract = await getPoolContract()
  const gatewayContract = getWrappedTokenGatewayContract()

  const steps = []

  if (amount === ALL) {
    if (symbol === 'ETH') throw new Error('ALL is not supported for ETH')

    amount = await getErc20Balance(symbol)
  }

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (Number(amount) > 0) {
    const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)
    if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, contract.address)).lt(ethers.BigNumber.from(typeof amountInSmallestUnit === 'number' ? amountInSmallestUnit.toFixed(0) : amountInSmallestUnit))) {
      steps.push(getApproveStep(symbol, contract.address, 'aaveV3'))
    }

    steps.push({
      id: 'deposit',
      description: `Deposit ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to ${symbol === 'ETH' ? 'WETH gateway' : 'liquidity pool'}`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        const hash = symbol === 'ETH'
          ? await waitForTxHashOnly(gatewayContract.depositETH(contract.address, get(connectedWalletAddress), aaveReferralCode, { value: amountInSmallestUnit }), setWaitingForUser)
          : await waitForTxHashOnly(contract.deposit(tokenAddress, amountInSmallestUnit, get(connectedWalletAddress), aaveReferralCode), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV3',
            type: 'deposit',
            symbol,
            amount: await getAmountForDisplay(symbol, amount)
          },
          icon: 'sign-in-alt',
          description: `Deposit ${formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} to Aave V3`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })
  }

  if (enable != null) {
    steps.push(...await aaveV3ActionEnableDisable(symbol, enable))
  }

  return steps
}

export async function aaveV3ActionWithdraw (symbol, amount) {
  await verifyPreconditions()

  const contract = await getPoolContract()
  const gatewayContract = getWrappedTokenGatewayContract()

  const steps = []

  // The WrappedTokenGateway contract must have an approved token allowance to spend aWETH (not a regular WETH)
  const tokenAddress = symbol === 'ETH' ? AWETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  let actualAmount = amount
  if (amount === ALL) {
    actualAmount = MAX_UINT256
  }

  const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, actualAmount)

  if (symbol === 'ETH') {
    if (ethers.BigNumber.from(await getAllowance(tokenAddress, gatewayContract.address)).lt(ethers.BigNumber.from(typeof amountInSmallestUnit === 'number' ? amountInSmallestUnit.toFixed(0) : amountInSmallestUnit))) {
      steps.push(
        getApproveStep(symbol, async () => ({ promise: approve(tokenAddress, gatewayContract.address) }), 'aaveV3', 'Approve WETH gateway access', 'Approve Aave V3 WETH gateway access for withdraw')
      )
    }
  }

  steps.push({
    id: 'withdraw',
    description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const hash = symbol === 'ETH'
        ? await waitForTxHashOnly(gatewayContract.withdrawETH(contract.address, amountInSmallestUnit, get(connectedWalletAddress)), setWaitingForUser)
        : await waitForTxHashOnly(contract.withdraw(tokenAddress, amountInSmallestUnit, get(connectedWalletAddress)), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'aaveV3',
          type: 'withdraw',
          symbol,
          ...amount === ALL ? { all: true } : { amount: Number(amount) }
        },
        icon: 'sign-out-alt',
        description: `Withdraw ${amount === ALL ? 'all deposited' : formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} from Aave V3`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function aaveV3ActionBorrow (symbol, amount, interestRateMode = 'Variable') {
  await verifyPreconditions()

  const contract = await getPoolContract()
  const gatewayContract = getWrappedTokenGatewayContract()

  const steps = []

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (amount === ALL) throw new Error('ALL is not supported for borrow')

  const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, amount)

  if (symbol === 'ETH') {
    // For unwrapped ETH, interest rate mode is always variable
    if (interestRateMode !== 'Variable') throw new Error('Only variable interest rate mode is supported for ETH')

    const wethVariableDebtContract = getWethVariableDebtContract()

    if (ethers.BigNumber.from(await wethVariableDebtContract.borrowAllowance(get(connectedWalletAddress), AAVE_V3_WRAPPED_TOKEN_GATEWAY)).lt(ethers.BigNumber.from(typeof amountInSmallestUnit === 'number' ? amountInSmallestUnit.toFixed(0) : amountInSmallestUnit))) {
      steps.push(getApproveStep(symbol, async () => ({ promise: wethVariableDebtContract.approveDelegation(AAVE_V3_WRAPPED_TOKEN_GATEWAY, MAX_UINT256) }), 'aaveV3', 'Approve WETH gateway access', 'Approve Aave V3 WETH gateway access for borrowing'))
    }
  }

  steps.push({
    id: 'borrow',
    description: `Borrow ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) from liquidity pool`,
    status: 'waiting',
    async sendTx (step, setWaitingForUser) {
      const hash = symbol === 'ETH'
        ? await waitForTxHashOnly(gatewayContract.borrowETH(contract.address, amountInSmallestUnit, 2, aaveReferralCode), setWaitingForUser)
        : await waitForTxHashOnly(contract.borrow(tokenAddress, amountInSmallestUnit, interestRateMode === 'Stable' ? 1 : 2, aaveReferralCode, get(connectedWalletAddress)), setWaitingForUser)
      const { tx, status } = await getTxStatusByHash(hash, true)
      if (!tx) throw new Error('Transaction not found after sending')

      upsertTransaction(toChecksumAddress(tx.from), hash, {
        data: {
          service: 'aaveV3',
          type: 'borrow',
          symbol,
          amount: Number(amount)
        },
        icon: 'sign-out-alt',
        description: `Borrow ${formatCurrency(Number(amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) from Aave V3`,
        status,
        nonce: Number(tx.nonce)
      })

      return hash
    }
  })

  return steps
}

export async function aaveV3ActionRepay (symbol, amount, interestRateMode = null) {
  await verifyPreconditions()

  const contract = await getPoolContract()
  const gatewayContract = getWrappedTokenGatewayContract()

  const steps = []

  let actualAmount = amount
  if (amount === ALL) {
    actualAmount = MAX_UINT256
  }

  const tokenAddress = symbol === 'ETH' ? WETH_TOKEN_ADDRESS : get(markets).coins[symbol]?.tokenAddress
  if (!tokenAddress) throw new Error(`No contract address found for ${symbol}`)

  if (!interestRateMode) {
    const amountInSmallestUnit = amount === ALL ? null : await getAmountInSmallestUnit(symbol, Number(amount))
    const amountBN = amountInSmallestUnit && ethers.BigNumber.from(amountInSmallestUnit)

    const userReserveData = await aaveV3GetUserReserveData(symbol)

    const stableDebtBalance = userReserveData.currentStableDebt
    const variableDebtBalance = userReserveData.currentVariableDebt

    if (!Number(stableDebtBalance) && !Number(variableDebtBalance)) return []

    if (stableDebtBalance.gt(0) && variableDebtBalance.gt(0)) {
      if (amount === ALL) {
        return [...await aaveV3ActionRepay(symbol, ALL, 'Stable'), ...await aaveV3ActionRepay(symbol, ALL, 'Variable')]
      } else {
        const priority = await dialogs.open(RepayPriorityPrompt, {
          symbol,
          stableAmount: await getAmountForDisplay(symbol, stableDebtBalance),
          variableAmount: await getAmountForDisplay(symbol, variableDebtBalance)
        })

        if (!priority) return

        const order = [{ type: 'Variable', balance: variableDebtBalance }, { type: 'Stable', balance: stableDebtBalance }]
        if (priority === 'Variable') order.reverse()

        const steps = []
        let toBePaid = ethers.BigNumber.from(amountBN)
        for (const repayObject of order) {
          if (toBePaid.gte(repayObject.balance)) {
            steps.push(...await aaveV3ActionRepay(symbol, ALL, repayObject.type))
            toBePaid = toBePaid.sub(repayObject.balance)
          } else {
            steps.push(...await aaveV3ActionRepay(symbol, toBePaid, repayObject.type))
            toBePaid = ethers.BigNumber.from(0)
            break
          }
        }

        if (!steps.length) throw new Error('No repay steps')
        return steps
      }
    } else if (stableDebtBalance.gt(0)) {
      return await aaveV3ActionRepay(symbol, amount, 'Stable')
    } else if (variableDebtBalance.gt(0)) {
      return await aaveV3ActionRepay(symbol, amount, 'Variable')
    } else {
      throw new Error('No debt to repay')
    }
  } else {
    let debtBalanceWithCoefficient

    if (symbol === 'ETH' && amount === ALL) {
      // For unwrapped ETH, interest rate mode is always variable
      if (interestRateMode !== 'Variable') throw new Error('Only variable interest rate mode is supported for ETH')
      // Getting the most recent debt balance straight from the contract
      const contractDebtBalance = await getWethVariableDebtContract().balanceOf(get(connectedWalletAddress))
      // Cover the interest added during the transaction confirmation. Excessive payment is refunded
      debtBalanceWithCoefficient = contractDebtBalance.add(contractDebtBalance.mul(REPAY_ETH_COEFFICIENT).div(REPAY_ETH_SCALE_FACTOR))
    }

    // We are comparing with half the maxiumum allowance here in case there are contracts that do not handle -1 separately and have already deducted a bit.
    const amountInSmallestUnit = await getAmountInSmallestUnit(symbol, actualAmount)
    const amountInSmallestUnitForApprovalCheck = amount === ALL ? HALF_MAX_UINT256 : await getAmountInSmallestUnit(symbol, actualAmount)
    if (symbol !== 'ETH' && ethers.BigNumber.from(await getAllowance(symbol, contract.address)).lt(ethers.BigNumber.from(typeof amountInSmallestUnitForApprovalCheck === 'number' ? amountInSmallestUnitForApprovalCheck.toFixed(0) : amountInSmallestUnitForApprovalCheck))) {
      steps.push(getApproveStep(symbol, contract.address, 'aaveV3'))
    }

    steps.push({
      id: 'repay',
      description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) to liquidity pool`,
      status: 'waiting',
      async sendTx (step, setWaitingForUser) {
        const hash = symbol === 'ETH'
          ? await waitForTxHashOnly(gatewayContract.repayETH(contract.address, amountInSmallestUnit, 2, get(connectedWalletAddress), { value: amount === ALL ? debtBalanceWithCoefficient : amountInSmallestUnit }), setWaitingForUser)
          : await waitForTxHashOnly(contract.repay(tokenAddress, amountInSmallestUnit, interestRateMode === 'Stable' ? 1 : 2, get(connectedWalletAddress)), setWaitingForUser)
        const { tx, status } = await getTxStatusByHash(hash, true)
        if (!tx) throw new Error('Transaction not found after sending')

        upsertTransaction(toChecksumAddress(tx.from), hash, {
          data: {
            service: 'aaveV3',
            type: 'repay',
            symbol,
            ...amount === ALL ? { all: true } : { amount: Number(amount) }
          },
          icon: 'sign-in-alt',
          description: `Repay ${amount === ALL ? 'all borrowed' : formatCurrency(await getAmountForDisplay(symbol, amount), undefined, -6, '-', true)} ${symbol} (${interestRateMode.toLowerCase()}) to Aave V3`,
          status,
          nonce: Number(tx.nonce)
        })

        return hash
      }
    })

    return steps
  }
}

import { removeUndefined } from './utils'
import { StatusCodeError } from './api'
import uri from 'uri-tag'

const FEE_MULTIPLIER = 1.25
const MIN_FEE = 4 // sat/vbyte
export const SATOSHI_MULTIPLIER = 1e8

export let Bitcoin

export async function ensureBitcoinLib () {
  if (!window.Bitcoin) {
    await import('/build/js/bitcoinjs-lib.js') // eslint-disable-line import/no-absolute-path
  }

  Bitcoin = window.Bitcoin
}

export async function generateBitcoinWallet () {
  await ensureBitcoinLib()

  const keyPair = Bitcoin.ECPair.makeRandom()
  const { address } = Bitcoin.payments.p2wpkh({ pubkey: keyPair.publicKey })

  return {
    privateKey: keyPair.toWIF(),
    address,
    keyPair
  }
}

export async function importBitcoinWallet (privateKey) {
  await ensureBitcoinLib()

  const keyPair = Bitcoin.ECPair.fromWIF(privateKey)
  const { address } = Bitcoin.payments.p2wpkh({ pubkey: keyPair.publicKey })

  return {
    privateKey: keyPair.toWIF(),
    address,
    keyPair
  }
}

export async function callBlockExplorerApi (method, path, data = {}, server = null) {
  if (!server) {
    let lastError
    for (const server of window.appVariables.bitcoinBlockExplorerServers) {
      try {
        return await callBlockExplorerApi(method, path, data, server)
      } catch (error) {
        console.warn('Failed to call block explorer API', server, method, path, data, error)
        lastError = error
      }
    }
    throw lastError
  } else {
    if (!path.startsWith('/')) throw new Error('Bad path')

    const hasBody = method !== 'GET'
    const isPlainBody = hasBody && typeof data === 'string'
    let query = ''
    if (!hasBody && Object.keys(data).length) {
      const params = new URLSearchParams()
      for (const [k, v] of Object.entries(data)) {
        if (v !== undefined) params.set(k, v)
      }
      const str = params.toString()
      if (str) query = (path.includes('?') ? '&' : '?') + str
    }

    const response = await fetch(server + path + query, {
      method,
      mode: 'cors',
      cache: 'no-store',
      headers: removeUndefined({
        'Content-Type': hasBody ? (isPlainBody ? 'text/plain' : 'application/json') : undefined
      }),
      redirect: 'follow',
      body: hasBody ? (isPlainBody ? data : JSON.stringify(data)) : undefined
    })

    let responseBody
    try {
      if (response.headers.get('content-type')?.startsWith('application/json')) {
        responseBody = await response.json()
      } else {
        responseBody = await response.text()
      }
    } catch (e) {
      console.warn('Failed to parse response body', e)
    }

    if (!response.ok) {
      console.warn(`Block explorer API error for ${method} ${path}`, responseBody)
      throw new StatusCodeError(response, responseBody)
    }

    return responseBody
  }
}

export async function getUtxos (address) {
  await ensureBitcoinLib()

  const response = await callBlockExplorerApi('GET', uri`/api/address/${address}/utxo`)
  return response
}

export async function getBalance (address, utxos = null) {
  await ensureBitcoinLib()

  if (!utxos) {
    utxos = await getUtxos(address)
  }

  let confirmedSat = 0n
  let unconfirmedSat = 0n

  for (const utxo of utxos) {
    if (utxo.status.confirmed) {
      confirmedSat += BigInt(utxo.value)
    } else {
      unconfirmedSat += BigInt(utxo.value)
    }
  }

  return {
    confirmedSat,
    unconfirmedSat,
    confirmed: Number(confirmedSat) / SATOSHI_MULTIPLIER,
    unconfirmed: Number(unconfirmedSat) / SATOSHI_MULTIPLIER
  }
}

export async function getFeeRate () {
  await ensureBitcoinLib()

  const response = await callBlockExplorerApi('GET', '/api/fee-estimates') // There is also /api/v1/fees/recommended but this works only for mempool.space
  return Math.max(MIN_FEE, response['1'] * FEE_MULTIPLIER) // sat/vbyte
}

export async function sweep (fromAddress, privateKey, toAddress, { dryRun = false, opReturnData = null, utxos = null } = {}) {
  await ensureBitcoinLib()

  const wallet = await importBitcoinWallet(privateKey)
  if (wallet.address !== fromAddress) throw new Error('Private key does not match address')

  if (!utxos) utxos = await getUtxos(fromAddress)
  const txDetails = {}

  const prepareTx = async (fee = 0n) => {
    const psbt = new Bitcoin.Psbt({ network: Bitcoin.networks.bitcoin })
    psbt.setVersion(2)
    psbt.setLocktime(0)

    // Add inputs
    let totalValue = 0n
    for (const utxo of utxos) {
      if (!txDetails[utxo.txid]) txDetails[utxo.txid] = await callBlockExplorerApi('GET', uri`/api/tx/${utxo.txid}`)

      const tx = txDetails[utxo.txid]
      const vout = tx.vout[utxo.vout]
      if (vout.scriptpubkey_type !== 'v0_p2wpkh') throw new Error(`Unsupported input of type ${vout.scriptpubkey_type} in ${utxo.txid} output ${utxo.vout}`)

      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,
        witnessUtxo: {
          script: Buffer.from(vout.scriptpubkey, 'hex'),
          value: BigInt(vout.value)
        }
      })
      totalValue += BigInt(vout.value)
    }

    const finalOutputValue = totalValue - fee

    // Add output
    psbt.addOutput({
      address: toAddress,
      value: finalOutputValue
    })

    // Add OP_RETURN output if requested
    if (opReturnData) {
      psbt.addOutput({
        script: Bitcoin.payments.embed({ data: [Buffer.from(opReturnData)] }).output,
        value: 0n
      })
    }

    // Sign and finalize
    psbt.signAllInputs(wallet.keyPair)
    psbt.finalizeAllInputs()

    const hex = psbt.extractTransaction().toHex()
    const txSize = psbt.extractTransaction().virtualSize()
    return { hex, finalOutputValue, txSize, fee }
  }

  const tempTx = await prepareTx()
  const feeRate = await getFeeRate()
  const fee = BigInt(Math.ceil(tempTx.txSize * feeRate))
  const finalTx = await prepareTx(fee)

  if (dryRun) return { ...finalTx, feeRate }

  const txId = await callBlockExplorerApi('POST', '/api/tx', finalTx.hex)
  return { txId, ...finalTx, feeRate }
}
